@import "../../variables";
@import "../home/Home";




.heart-health-container {
  @extend .home-container;
  max-width: $container;
  position: relative;
  margin: 0 auto;
  display: flex;

  .heart-health-sidebar {
    position: fixed;
    top: 100px;
    width: $container-mobile;
    padding-right: $spacing-lg;
  }

  .heart-health-content {
    max-width: $container - $container-mobile;
    padding: $spacing-xl $spacing-lg 0 ($container-mobile + $spacing-lg);

    h2 {
      font-size: 52px;
      font-weight: lighter;
      text-align: center;
      color: $colour-primary;
      margin: 0;
    }

    .read-length {
      margin: 0;
      font-size: 14px;
      text-align: center;
    }

    .advice {
      font-size: 18px;
      color: $colour-primary;
      text-align: center;
      margin: $spacing-xxl 0;
    }

    img {
      max-width: 100%;
    }

    .heart-health-content-item {
      padding: $spacing-xl 0;

      &:after {
        width: 99px;
        border-bottom: solid 1px $colour-primary-darker;
        display: block;
        content: " ";
        margin: 0 auto;
        padding-top: $spacing-xl;
      }
      
      h3 {
        margin: $spacing-xl 0;
        color: $colour-primary;
        font-weight: normal;
        text-align: center;
        font-size: 24px;
      }

      .split-view {
        display: flex;

        img {
          align-self: flex-start;
          width: 287px;

          &:first-child {
            padding-right: $spacing-lg;
          }

          &:last-child {
            padding-left: $spacing-lg;
          }
        }

        section {
          flex: 1;
          align-self: flex-start;

          &:first-child {
            padding-right: $spacing-lg;
          }

          &:last-child {
            padding-left: $spacing-lg;
          }
        }
      }
    }
  }
}
