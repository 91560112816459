@import '../../variables.scss';

.divider {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000000001;
    height: 100vh;
    width: 100vw;
    background-color: $colour-white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.4s linear;
    pointer-events: none;

    &.show {
        animation: quickFade 0.4s;
        opacity: 1;
        pointer-events: all;
    }

    img {
        max-width: 128px;
        max-height: 200px;
    }

    h2 {
        font-size: 36px;
        margin: 0;
        text-transform: uppercase;
    }

    h3 {
        font-size: 20px;
        margin: 0 $spacing-lg $spacing-lg $spacing-lg;
        color: $colour-primary;
        text-align: center;
    }
}

@keyframes quickFade {
    0% { opacity: 0; }
    100% { opacity: 1; }
}