@import "../../variables.scss";

.full-parameters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
    max-width: 640px;
    margin: 0 auto;
    padding: $spacing-xl 0 150px 0;
    font-size: 14px;
    
    dt {
      font-family: $font-bold;
      width: 60%;
      display: block;
      margin: $spacing 0;
      text-align: left;
    }
    
    dd {
      width: 40%;
      text-align: right;
      display: block;
      margin: $spacing 0;
    }
  }