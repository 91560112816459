@import "../../variables";

.checklist {
    min-height: calc(100vh - 275px);

    > .content {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        padding: $spacing-xxl $spacing-xl;
        box-sizing: border-box;
        max-width: $container-xxs;
        margin: 0 auto;

        @media screen and (max-width: $container-sm) {
            padding-bottom: 200px;
        }

        h2 {
            font-weight: 400;
            color: $colour-primary;
            margin: $spacing-xs 0;
        }

        p {
            margin: $spacing-xs 0;
        }

        ul {
            margin: 20px 0;
            padding-left: 15px;

            li {
                font-size: 15px;
                margin-bottom: 20px;
                text-indent: -15px;
                list-style-type: none;

                &:before {
                    font-size: 24px;
                    line-height: 18px;
                    position: relative;
                    top: 2px;
                    content: "\2022 ";
                    left: -4px;
                    color: $colour-primary;
                }

                em {
                    font-style: normal;
                    font-weight: bold;
                    color: $colour-error;
                }
            }
        }

        .start-test {
            text-align: center;
            font-size: 14px;
            width: 100%;
            // transition: opacity 0.25s ease-out, bottom 0.4s ease-out;
            cursor: pointer;
            text-decoration: none;

            &.leaving {
                opacity: 0;
                bottom: $spacing-xxxl;
            }

            h3 {
                font-size: 18px;
                color: $colour-primary;
            }

            img {
                width: 37px;
                position: relative;
                animation: arrowScroll 2s infinite;
            }

            @media screen and (max-height: $mobile-height-md) {
                bottom: $spacing-sm;
                font-size: 12px;

                img {
                    width: 20px;
                }
            }
        }
    }

    iframe {
        border: none;
        margin-bottom: $spacing-lg;
        width: $container-xxs;
        height: $container-xxs / 16 * 9;

        @media screen and (max-width: $container-xxs) {
            width: 100%;
            height: auto;
        }
    }

    .button {
        width: 100px;
        font-size: 18px;
        line-height: 20px;
        margin: $spacing-xs auto;
    }
}
