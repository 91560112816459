@import "../../variables";

.not-found-container {
  margin: $spacing-xl auto;
  padding: $spacing-xl;
  max-width: $container-xxs;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    width: auto;
    margin: $spacing-sm 0;
  }

  h1 {
    color: $colour-primary-lighter;
    text-align: center;
    margin: $spacing-sm 0;
    font-size: 21px;
  }
  
  p {
    margin: $spacing-sm 0;
  }
}
