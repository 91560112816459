@import "../../variables.scss";

.help-container {
    max-width: $container-sm;
    margin: 0 auto;
    padding: $spacing-lg;
    
    h1, h3 {
        color: $colour-primary;
        font-weight: 400;
    }

    .disclaimer {
        font-family: $font-bold;
    }
}