@import "../../../variables";


.family-history-kidney-disease-step {
  .family-history-items,
  .kidney-disease-items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .option-toggle {
      height: 60px;
      
      @media screen and (max-height: $mobile-height-md) {
        height: 40px;
      }
    }

    .family-history-item {
      width: 100px;
      margin: $spacing-xxs $spacing-sm;

      @media screen and (max-height: $mobile-height-md) {
        width: 50px;
        margin: $spacing-xxs;
      }
    }

    .kidney-disease-item {
      margin: $spacing-xxs $spacing-sm;

      &:nth-of-type(2) {
        width: 130px;

        @media screen and (max-height: $mobile-height-md) {
          width: 120px;
        }
      }
    }
  }
}
