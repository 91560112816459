@import "../../../variables";

.smoking-step {
  @media screen and (max-height: $mobile-height-md) {
    margin-top: 0 !important;
  }

  article {
    padding: $spacing-xxs $spacing !important;
  }

  .smoking-items {
    display: flex;
    flex-wrap: wrap;

    .smoking-item {
      width: 66px;
      padding: 0 $spacing-xxs;
      line-height: 0.9rem;

      @media screen and (min-width: $container-sm) {
        width: 108px;
      }

      @media screen and (max-height: $mobile-height-sm) {
        width: 90px;
        height: 47px;
      }
      
     

     

    }
  }

  .option-toggle {
  
    @media screen and (max-height: $mobile-height-xsm) {
        font-size: 11px;
    }
  }

  .family-history-items {
    display: flex;
    justify-content: center;

    .family-history-item {
        padding: 5px;
        width: 65px;
        height: 45px;
    }
  }
}
