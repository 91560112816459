@import "../../variables";

.blood-test {
    > .content {
        max-width: $container-xxs;
        margin: 0 auto;
        padding: $spacing-xxl;
        display: flex;
        align-items: center;
        min-height: calc(100vh - 120px);
        flex-direction: column;

        @media screen and (max-width: $container-sm) {
            padding-bottom: 200px;
        }

        .intro {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            img {
                margin: 0 auto;
                display: block;
            }

            > div > h2 {
                margin: $spacing 0;
                text-align: center;
                font-weight: 400;
                color: $colour-primary;
            }

            p {
                margin: $spacing 0;
                font-weight: 400;
                font-size: 16px;
                line-height: 21px;
                display: flex;
                justify-content: space-between;

                em {
                    font-style: normal;
                    font-weight: bold;
                    color: $colour-error;
                }
            }

            h3 {
                color: $colour-primary;
                font-size: 18px;
            }

            @media screen and (max-width: $container-xs) {
                flex-direction: column;

                img {
                    align-self: center;
                }
            }
        }
    }

    .help {
        flex: 0 0 27px;
        height: 26px;
        margin-top: -2px;
        border-radius: 15px;
        background-position: center;
        background-image: url("../../../public/assets/help.png");
        background-repeat: no-repeat;
        text-indent: -9999px;
        color: $colour-white;
        display: block;
        font-family: $font-bold;
        text-align: center;
        font-size: 14px;
        line-height: 26px;
        cursor: pointer;

        @media screen and (min-height: $container-sm) {
            font-size: 16px;
        }
    }

    form {
        width: 100%;
        max-width: $container-mobile-lg;
        margin: 0 auto;

        .info {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
        }
    }

    .input-field {
        margin: $spacing-sm 0;

        select {
            max-width: none;
        }
    }

    .field-card {
        background-color: transparent;
        border: solid 1px $colour-mid-grey;
        max-width: 100%;

        header {
            border-bottom: solid 1px $colour-mid-grey;

            h4 {
                font-size: 16px;
                color: $colour-black;
            }
        }

        article {
            padding-top: $spacing-sm;
        }
    }

    .address-not-listed {
        cursor: pointer;
        font-size: 13px;
        text-decoration: underline;
        display: block;
        text-align: right;
    }

    .address-lookup {
        padding: $spacing-xs 0;
        border: solid 1px $colour-mid-grey;
        background-color: $colour-offwhite;
        height: 200px;
        overflow-y: auto;

        li {
            margin: 0;
            padding: $spacing-xxs $spacing-sm;
            list-style-type: none;
            font-size: 14px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            cursor: pointer;

            &:hover {
                background-color: $colour-mid-grey;
                color: $colour-white;
            }
        }
    }

    .submit {
        display: flex;
        justify-content: center;
        align-items: center;

        .button {
            width: auto;
            display: inline-block;
            font-size: 16px;
            padding: $spacing-sm $spacing;
        }
    }
}
