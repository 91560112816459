@import "../../variables.scss";

footer {
   background-color: $colour-almostwhite;
   padding: $spacing-xl;

   @media screen and (max-width: $container-sm) {
       display: none;
   }
   
  .powered-by {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    line-height: 18px;
    font-family: $font-bold;
    color: $colour-dark-grey;
    margin: $spacing 0;
    width: 100%;
    justify-content: center;

    img {
      display: block;
      margin-left: $spacing-xs;
      height: 16px;
    }
  }

  .ce-mark {
    width: 20px;
    display: block;
    margin: 0 auto;
  }

  .terms {
    max-width: $container-xxs;
    margin: $spacing-sm auto;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    a {
      font-size: 14px;
      color: $colour-dark-grey;
      margin: $spacing-xs;
      font-family: $font-bold;
    }
  }
}