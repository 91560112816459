@import "../../../variables.scss";

section.physical-activity-step {
    padding-top: 80px;
    margin-top: $spacing-sm;

    .range-group {
        margin-bottom: $spacing-xxxl;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    p {
        line-height: 21px;

        span {
            opacity: 0.5;
        }
    }

    p:first-of-type {
        margin-top: 0;
    }

    img {
        margin: 0 auto;
        max-width: 128px;
        display: block; 
        // transition: top 1s ease-out, right 1s ease-out;
        top: 0 !important;
        right: -50px;
        position: absolute;
        z-index: 3;
    }

    .physical-activity-items {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: $spacing-xl;

        .physical-activity-item {
            width: 120px;
            height: 55px;
            font-size: 14px;
            line-height: 1.14;
            padding: $spacing-sm $spacing;
            box-sizing: border-box;
            font-size: 13px;

            &.selected {
                width: 123px;
                margin-top: $spacing-xxs;
                padding: $spacing;
            }
        }
    }

    @media screen and (max-height: $mobile-height-md) {
        img {
            max-height: 60px;
        }

        p {
            font-size: 12.5px;
        }
    }

    @media screen and (max-height: $mobile-height-sm) {
        p {
            margin: $spacing-xxs 0;
        }

        .physical-activity-items {
            .physical-activity-item {
                width: 105px;
                padding: $spacing-xs;

                &.selected {
                    width: 108px;
                }
            }
        }
    }
}

.step.active .physical-activity-step img {
    top: 0 !important;
    right: 0;
}

.step.previous .physical-activity-step img {
    top: 0 !important;
    right: 50px;
}