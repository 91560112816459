@import "../../variables";

.pin-container {
    margin: 0 auto;
    max-width: $container-xxs;
    min-height: calc(100vh - 365px);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: $spacing-xxl $spacing-xl;

    p {
        text-align: left;
        display: block;
        width: 100%;

        strong {
            font-family: $font-bold;
            color: $colour-error;
        }
    }
    
    .verification-error {
        padding: 0 $spacing-sm $spacing $spacing-sm;
        font-size: 16px;
        font-family: $font-bold;
        color: $colour-error;
        text-align: center;
        margin: 0;

        img {
            display: block;
            margin: $spacing-xs auto;
        }
    }

    h2 {
        font-weight: 400;
        color: $colour-primary;
        margin: $spacing 0;
        text-align: center;
    }

    label {
        color: $colour-dark-grey;
        display: block;
        font-size: 16px;

        @media screen and (max-height: $mobile-height-md) {
            font-size: 12px;
        }
    }

    form {
        width: 100%;
        max-width: $container-mobile;
        margin: 0 auto;
    }

    input[type=text],
    input[type=password],
    input[type=number] {
        display: block;
        padding: $spacing-xxs;
        border-radius: 5px;
        margin: 10px 0;
        box-sizing: border-box;
        width: 100%;
        border: solid 1px $colour-light-grey;
        font-family: $font-default;
    }

    
  .button {
    width: 100px;
    font-size: 18px;
    line-height: 20px;
    margin: $spacing-lg auto;
  }

}  