@import "../../variables";

.welcome-mobile {
    position: relative;
    width: 100%;    
    margin: 0 auto;
    background-color: $colour-primary;
    padding: 120px 0 $spacing-xl 0;
    top: -90px;
    margin-bottom: -90px;

    img {
        display: block;
        margin: 0 auto;
        max-width: 90%;
    }

    h2 {
        text-align: center;
        font-size: 18.5px;
        font-family: $font-bold;
        margin: $spacing-xs 0;
        color: $colour-primary-darker;
    }

    .oval {
        height: 385px;
        width: 50%;
        position: absolute;
        left: 0;
        top: -$spacing-xxl;
        border-top-right-radius: 300px;
        border-bottom-right-radius: 300px;
        background: -moz-linear-gradient(left, $colour-white 0%, $colour-secondary 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, $colour-white 0%,$colour-secondary 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, $colour-white 0%,$colour-secondary 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        z-index: -1;
    }

    @media screen and (max-height: $mobile-height-lg) {
        img.hero {
            width: 180px;
        }
    }

    @media screen and (max-height: $mobile-height-sm) {
        img.hero {
            width: 100px;
        }
    }

    @media screen and (min-width: $container-sm + 1) {
        display: none;
    }
}
