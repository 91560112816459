@import "../../variables";

.blood-pressure-kit {
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  overflow-y: scroll;
  z-index: 9999;
  background: -moz-linear-gradient(top, $colour-white 30%, $colour-secondary-gradient 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, $colour-white 30%, $colour-secondary-gradient 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, $colour-white 30%, $colour-secondary-gradient 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  display: flex;
  // transition: padding-top 0.5s ease-out;

  &.leaving {
    padding-top: 100vh;

    header,
    header.close {
      top: 100vh;
    }
  }

  header {
    color: $colour-white;
    padding: $spacing;
    width: 100vw;
    box-sizing: border-box;
    // transition: top 0.5s ease-out;
    z-index: 2001;
    position: fixed;

    > div {
      max-width: $container;
      box-sizing: border-box;
      margin: 0 auto;
      padding: 0 $spacing-md;
    }

    &.close {
      background: $colour-black;
      top: 0px;

      .close {
        font-family: $font-bold;
        font-size: 14px;
        line-height: 14px;
        cursor: pointer;
      }
    }
  }

  .content {
    max-width: $container-xs;
    margin: 0 auto;
    padding: $spacing-xxl;
    box-sizing: border-box;
    width: 100%;

    .intro {
      display: flex;

      img {
        margin-right: $spacing-xl;
        align-self: center;
      }

      p {
        margin: 0;
        font-family: $font-bold;

        span {
          color: $colour-primary;
        }
      }
    }
  }

  @media screen and (max-height: $mobile-height-md) {
    .content {
      padding: $spacing-xxl $spacing-md $spacing-md $spacing-md;
        
      .intro {
        p {
          font-size: 13px;
        }
      }
    }
  }

  .input-field {
    margin: $spacing-sm 0;
  }

  .address-not-listed {
    cursor: pointer;
    font-size: 13px;
    text-decoration: underline;
    display: block;
    text-align: right;
  }

  .address-lookup {
    padding: $spacing-xs 0;
    border: solid 1px $colour-mid-grey;
    background-color: $colour-offwhite;
    height: 200px;
    overflow-y: auto;

    li {
      margin: 0;
      padding: $spacing-xxs $spacing-sm;
      list-style-type: none;
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        background-color: $colour-mid-grey;
        color: $colour-white;
      }
    }
  }

  .submit {
    display: flex;
    justify-content: center;
    align-items: center;
    
    .button {
      width: auto;
      display: inline-block;
      font-size: 16px;
      padding: $spacing-sm $spacing;
    }
  }
}
