@import "../../variables.scss";

$dialSize: 140px;

.dial-container {
    margin: $spacing-xxs;
    border: solid 1px $colour-primary;
    width: $dialSize + $spacing + $spacing;
    border-radius: 5px;
    background-color: $colour-white;

    &.boxless {
        border: none;
        background-color: transparent;

        h3 { 
            display: none;
        }

        .dial {
            margin: $spacing-sm 0;
            padding: 0;

            .indicator, .wheel { display: none; }
            .counter {
                margin: 0 auto;
                width: 60px;
                height: 60px;
                position: relative;
                top: auto;
                left: auto;
                box-shadow: none;
                font-size: 21px;
                margin-bottom: 50px;
            } 
        }
    }

    &.no-boundaries {
        .dial {
            .counter {
                border: solid 2px $colour-primary;
                background-color: $colour-white !important;
                color: $colour-black;
            }
        }
    }

    &.disabled {
        .dial {
            opacity: 0.25;
            pointer-events: none;

            .counter, .indicator {
                text-indent: -9999px;
                background-color: $colour-white !important;
            }
        }
    }

    h3 {
        margin: 0;
        padding: $spacing-sm 0;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        color: $colour-white;
        font-family: $font-bold;
        font-size: 18px;
        background-color: $colour-primary;
    }
    
    .dial {
        position: relative;
        padding: $spacing;

        .indicator {
            transform: rotate(-80deg);
            position: absolute;
            z-index: 20;
            top: -4px;
            height: 87px;
            left: ($dialSize / 2) - 5 + $spacing;
            transform-origin: bottom center;
            transition: transform 1.5s;
            transition-timing-function: cubic-bezier(0, 0, 0.25, 1);

            &:after {
                content: " ";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 4px 65px 4px;
                border-color: transparent transparent $colour-black transparent;
            }
        }

        .wheel {
            transform: rotate(270deg);
            position: relative;
            overflow: hidden;
            width: $dialSize;
            height: $dialSize;

            .colour {
                clip: rect(0, $dialSize, $dialSize, $dialSize / 2);
                position: absolute;
                width: $dialSize;
                height: $dialSize;

                &:after {
                    content: " ";
                    position: absolute;
                    border-radius: 50%;
                    left: calc(50% - #{$dialSize / 2});
                    top: calc(50% - #{$dialSize / 2});
                    width: $dialSize;
                    height: $dialSize;
                    clip: rect(0, $dialSize / 2, $dialSize, 0);
                    display: block;
                }

                &:nth-child(1):after {
                    background-color: $colour-risk-low;
                    transform: rotate(60deg);
                    z-index: 12;
                }

                &:nth-child(2):after {
                    background-color: $colour-risk-med;
                    transform: rotate(120deg);
                    z-index: 11;
                }

                &:nth-child(3):after {
                    background-color: $colour-risk-high;
                    transform: rotate(180deg);
                    z-index: 10;
                }
            }
        }
        
        .counter {
            background-color: $colour-risk-low;
            color: $colour-white;
            position: absolute;
            top: calc(50% - #{$spacing-sm} - 30px);
            left: calc(50% - #{$spacing-sm} - 15px);
            height: 30px;
            width: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: $spacing-sm;
            border: solid 1px $colour-white;
            font-family: $font-bold;
            font-size: 18px;
            border-radius: 50%;
            box-shadow: 0px 0px 10px rgba(0,0,0,0.7);
            z-index: 25;
            transition: background-color 1s;
            transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
            text-align: center;

            span {
                display: block;
                font-size: 14px;
                line-height: 11px;
                margin-top: $spacing-xxs;
            }
        }

        &.low-risk {
            .indicator {
                transform: rotate(-60deg);
            }

            .counter {
                background-color: $colour-risk-low;
            }
        }

        &.med-risk {
            .indicator {
                transform: rotate(0deg);
            }

            .counter {
                background-color: $colour-risk-med;
            }
        }

        &.high-risk {
            .indicator {
                transform: rotate(60deg);
            }

            .counter {
                background-color: $colour-risk-high;
            }
        }
        
        &.custom-interpretation {
            
        }

        &.with-lower-bound {
            .wheel {
                .colour {
                    &:nth-child(1):after {
                        background-color: $colour-risk-high;
                        transform: rotate(45deg);
                        z-index: 12;
                    }

                    &:nth-child(2):after {
                        background-color: $colour-risk-low;
                        transform: rotate(90deg);
                        z-index: 11;
                    }

                    &:nth-child(3):after {
                        background-color: $colour-risk-med;
                        transform: rotate(135deg);
                        z-index: 10;
                    }

                    &:nth-child(4):after {
                        background-color: $colour-risk-high;
                        transform: rotate(180deg);
                        z-index: 9;
                    }
                }
            }

            &.too-low { 
                .indicator {
                    transform: rotate(-67.5deg); 
                }

                .counter {
                    background-color: $colour-risk-high;
                }
            }

            &.low-risk .indicator { transform: rotate(-22.5deg); }
            &.med-risk .indicator { transform: rotate(22.5deg); }
            &.high-risk .indicator { transform: rotate(67.5deg); }
        }

        &.with-higher-bound {
            .wheel {
                .colour {
                    &:nth-child(1):after {
                        background-color: $colour-risk-low;
                        transform: rotate(45deg);
                        z-index: 12;
                    }

                    &:nth-child(2):after {
                        background-color: $colour-risk-med;
                        transform: rotate(90deg);
                        z-index: 11;
                    }

                    &:nth-child(3):after {
                        background-color: $colour-risk-medhigh;
                        transform: rotate(135deg);
                        z-index: 10;
                    }

                    &:nth-child(4):after {
                        background-color: $colour-risk-high;
                        transform: rotate(180deg);
                        z-index: 9;
                    }
                }
            }

            &.high-risk { 
                .counter {
                    background-color: $colour-risk-medhigh;
                }
            }
            
            &.very-high-risk { 
                .counter {
                    background-color: $colour-risk-high;
                }
            }

            &.low-risk .indicator { transform: rotate(-67.5deg); }
            &.med-risk .indicator { transform: rotate(-22.5deg); }
            &.high-risk .indicator { transform: rotate(22.5deg); }
            &.very-high-risk .indicator { transform: rotate(67.5deg); }
        }
    }
}