@import "../../variables";

.table-of-contents-header {
  text-transform: uppercase;
  font-weight: normal;
}

.table-of-contents {
  padding: 0;
  border-top: solid 1px $colour-primary;

  a {
    color: $colour-black;
    text-decoration: none;
  }

  .item {
    list-style-type: none;
    padding: $spacing;
    margin: 0;
    font-size: 16px;
    font-family: $font-bold;
    border-bottom: solid 1px $colour-primary;

    &.active {
      background-color: $colour-secondary-lighter;
    }
  }
}
