@import "../../../variables.scss";

.bp-warning-step {
    img {
        margin: $spacing-lg auto;
        display: block;
        z-index: 1;

        @media screen and (max-height: $mobile-height-sm) {
            width: 80px;
        }
    }

    strong {
        color: $colour-error;
    }
}
