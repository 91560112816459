@import "../../variables.scss";

.advice-container {
    max-width: $container-xs;
    padding: $spacing-lg $spacing-xxl;
    margin: 0 auto;
    padding-bottom: 100px;

    h1 {
        color: $colour-primary;
        font-weight: 400;
        margin-bottom: $spacing-xs;
        text-align: center;
    }

    .read-length {
        margin-top: 0;
        text-align: center;
    }

    section {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: $spacing-md 0;
        justify-content: center;
        align-items: center;

        h2 {
            width: 100%;
            font-weight: 400;
            color: $colour-primary;
        }

        img {
            max-width: 100%;
            
            &.left { margin-right: $spacing-xxl; max-width: 72px; }
            &.center { margin-bottom: $spacing-xxl; }
            &.right { margin-left: $spacing-xxl; }

            @media screen and (max-width: $container-mobile-lg) {
                margin: 0 0 $spacing-lg 0 !important;
            }
        }

        .advice-content {
            flex: 1;

            &.break {
                width: 100%;
                flex: auto;
            }
        }
    }
}