@import "../../variables";
@import "../home/Home";

.instructions-container {
  @extend .home-container;
  max-width: $container;
  position: relative;
  margin: 0 auto;
  padding-bottom: 100px;

  article {
    padding: $spacing-xl;
  }

  h2 {
    text-align: center;
    color: $colour-primary;
  }

  iframe {
    border: none;
    margin: $spacing-xxxl auto;
    width: $container-xxs;
    height: $container-xxs / 16 * 9;
    display: block;

    @media screen and (max-width: $container-xxs) {
      width: 100%;
      height: auto;
    }
  }
}
