@import "../../variables";

header.section-header {
  // In the latest version we don't show this
  display: none;
  // But let's keep it just in case
  background: $colour-secondary-darker;
  color: $colour-black;
  text-align: center;
  padding: $spacing-md;
  opacity: 0;
  // transition: opacity 0.4s ease-out;

  h3 {
    margin: 0;
    font-weight: normal;
    font-size: 21px;
  }

  &.medical {
    background: $colour-tertiary-lighter;
  }

  @media screen and (max-width: $mobile-height-lg) {
    padding: $spacing;

    h3 {
      font-size: 18px;
    }
  }

  @media screen and (max-width: $mobile-height-lg) {
    padding: $spacing-sm;

    h3 {
      font-size: 14px;
    }
  }
}

.active, .previous {
  header.section-header {
    opacity: 1;
  }
}

.final {
  header.section-header {
    opacity: 0;
  }
}
