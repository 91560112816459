@import "../../variables";

.council-banner {
    position: absolute;
    top: 0px;
    z-index: 20000;
    width: 100%;
    height: 80px;
    background-color: $colour-white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-container {
    article {
        max-width: $container-xxs;
        margin: 0 auto;

        h2 {
            text-align: center;
            font-family: $font-bold;
            color: $colour-primary;
            font-size: 24px;
        }

        h3 {
            font-family: $font-bold;
            color: $colour-primary;
            font-size: 18px;
        }

        p,
        ul {
            font-size: 16px;
            line-height: 22px;
        }

        img {
            display: block;
            margin: $spacing auto;
        }
    }

    .desktop-only {
        position: relative;
        max-width: $container;
        margin: $spacing-xxxl auto;
    }

    .powered-by {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        line-height: 18px;
        font-family: $font-bold;
        color: $colour-dark-grey;
        margin: $spacing 0 0 0;
        width: 100%;
        justify-content: center;

        img {
            display: block;
            margin-top: $spacing-xs;
            height: 16px;
        }
    }

    .council-logo {
        display: block;
        padding: $spacing;
        margin: 0 auto;
    }

    .ce-mark {
        width: 20px;
        display: block;
        margin: 0 auto;
        padding-top: $spacing-xl;
    }

    .terms {
        max-width: $container;
        margin: $spacing-sm auto;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        a {
            font-size: 14px;
            color: $colour-dark-grey;
            margin: $spacing-xs;
            font-family: $font-bold;
        }
    }
}
