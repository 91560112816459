@import "../../variables";

.no-idea {
  margin: $spacing;
  padding: $spacing $spacing $spacing 75px;
  text-align: center;
  border-radius: 10px;
  border: solid 1px $colour-mid-grey;
  background-color: $colour-white;
  color: $colour-dark-grey;
  font-family: $font-bold;
  background-image: url("../../../public/assets/confused-man.png");
  background-position: bottom left;
  background-size: 75px;
  background-repeat: no-repeat;
  cursor: pointer;
  // transition: top 1s ease-out;
  position: relative;
  top: 150px;

  h4 {
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 13px;
  }

  @media screen and (max-height: $mobile-height-sm) {
    margin: $spacing-sm $spacing;

    h4 {
      font-size: 14px;
    }

    p {
      font-size: 12px;
    }
  }
}

.step.active .no-idea,
.step.previous .no-idea {
  top: 0;
}
