@import url("https://fonts.googleapis.com/css?family=Istok+Web&display=swap");

$base-font-size: 16px;
$font-default: "Frutiger LT W01_55 Roma1475738", "Istok Web", Arial, Helvetica, sans-serif;
$font-bold: "Frutiger LT W01_65 Bold1475746", "Istok Web", Arial, Helvetica, sans-serif;

$spacing: 15px;
$spacing-xxs: $spacing / 3;
$spacing-xs: $spacing / 2;
$spacing-sm: $spacing / 1.5;
$spacing-md: $spacing * 1.25;
$spacing-lg: $spacing * 1.5;
$spacing-xl: $spacing * 2;
$spacing-xxl: $spacing * 3;
$spacing-xxxl: $spacing * 4;
$spacing-xxxxl: $spacing * 5;

$colour-primary: #0072BC;
$colour-primary-gradient: #005EB8;
$colour-primary-mid: #005EB8;
$colour-primary-darker: #003087;
$colour-primary-lighter: #4699ce;
$colour-primary-shadow: #00A9CE;

$colour-secondary: #009639;
$colour-secondary-darker: #006747;
$colour-secondary-darkest: #006747;
$colour-secondary-gradient: #006747;
$colour-secondary-lighter: #78BE20;

$colour-tertiary: #4a5279;
$colour-tertiary-darker: #222744;
$colour-tertiary-lighter: #b3e0e4;

$colour-quadiary: #2e92a1;
$colour-quadiary-lighter: #e2f5f9;

$colour-white: #ffffff;
$colour-almostwhite: #F0F4F5;
$colour-offwhite: #f1f1f1;
$colour-grey: #ededed;
$colour-light-grey: #cdcdcd;
$colour-mid-grey: #979797;
$colour-dark-grey: #666666;
$colour-darker-grey: #454545;
$colour-black: #000000;

$colour-affirmative: #44d54c;
$colour-error: #DA291C;
$colour-error-shadow: #ff9b9b;

$colour-risk-low: #4AD311;
$colour-risk-med: #f7c100;
$colour-risk-medhigh: #fc6262;
$colour-risk-high: #ff0c0c;

$container: 1200px;
$container-sm: 960px;
$container-xs: 720px;
$container-xxs: 540px;
$container-mobile-lg: 374px;
$container-mobile: 320px;

$mobile-height-xl: 812px;
$mobile-height-lg: 736px;
$mobile-height-md: 667px;
$mobile-height-sm: 568px;
$mobile-height-xsm: 500px;