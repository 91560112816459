@import "../../variables.scss";

.disclaimers-container {
    max-width: $container-sm;
    margin: 0 auto;
    padding: $spacing-lg;
    
    h1 {
        color: $colour-primary;
        font-weight: 400;
    }
    
    table {
        border-collapse: collapse;

        th, td {
            border: solid 1px $colour-light-grey;
            padding: $spacing;
            text-align: left;
        }
    }
}