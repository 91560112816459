@import "../../../variables";


.ethnicity-step {
  .ethnicity-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media screen and (min-width: $container-sm) {
      .ethnicity-item {
        width: 110px;
      }
    }
  }
}
