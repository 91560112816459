@import "../../variables.scss";

.cookies-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.7);
    z-index: 2000;

    .cookie-popup {
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: #F7F5F5;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.7);
        z-index: 200;

        .container {
            padding: $spacing-lg $spacing;
            max-width: $container-sm;
            margin: 0 auto;
            text-align: center;
            font-size: 13px;

            h4 {
                margin: 0;
                font-size: 13px;
            }

            a {
                color: $colour-primary;
                display: inline-block;
                margin: 0 $spacing-sm;
            }

            button {
                font-size: 13px;
                background-color: $colour-primary;
                color: $colour-white;
                border: none;
                font-family: $font-default;
                font-family: $font-bold;
                padding: $spacing-xxs $spacing-sm;
                border-radius: 5px;
                cursor: pointer;
            }
        }
    }
}