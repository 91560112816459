@import "../../../variables";


.atrial-fibrillation-step {
  .atrial-fibrillation-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .atrial-fibrillation-item {
      width: 100px;
      height: 100px;
      margin: $spacing-xxs $spacing;

      @media screen and (max-height: $mobile-height-md) {
        width: 75px;
        height: 75px;
      }
    }
  }
}
