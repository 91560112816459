@import "../../variables";

.verification-container {
  margin: $spacing-xl auto;
  max-width: $container-xxs;
  min-height: calc(100vh - 320px);
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 150px;

  .pin-forgotten {
    font-size: 12px;
    color: $colour-dark-grey;

    a {
      color: $colour-primary;
    }
  }

  @media screen and (max-height: $mobile-height-md) {
    justify-content: flex-start;
  }

  .button {
    width: 100px;
    font-size: 18px;
    line-height: 20px;
    margin: $spacing-xs auto;
  }

  h2 {
    font-weight: 400;
    color: $colour-primary;
    margin: $spacing 0;
    text-align: center;
  }


  form {
    max-width: $container-mobile;
    margin: 0 auto;
    width: 90%;
  }

  .verification-error,
  .verification-headline {
    padding: 0 $spacing-sm $spacing $spacing-sm;
    font-size: 20px;
    font-family: $font-bold;
    color: $colour-error;
    text-align: center;
    margin: 0;
    max-width: $container-mobile;

    @media screen and (max-width: $container-mobile) {
      font-size: 18px;
    }

    img {
      display: block;
      margin: $spacing-xs auto;
    }
  }

  label {
    color: $colour-black;
    display: block;
    font-size: 20px;

    @media screen and (max-height: $mobile-height-md) {
      font-size: 16px;
    }
  }

  input[type=text],
  input[type=password],
  input[type=number] {
    display: block;
    padding: $spacing-xxs;
    border-radius: 5px;
    margin: 10px 0;
    box-sizing: border-box;
    width: 100%;
    border: solid 1px $colour-light-grey;
    font-family: $font-default;
    font-size: 20px;
  }
  
  select {
    padding: $spacing-xs;
    width: 100%;
    margin: $spacing 0;
    border: solid 1px $colour-light-grey;
    border-radius: 5px;
  }
  
  fieldset.for-checkbox {
    border: none;
    padding: 0;
    margin: $spacing-lg 0;
    display: flex;
    cursor: pointer;

    label {
        cursor: pointer;
        max-width: 300px;
        width: calc(100% - 40px);
        display: inline-block;
        font-size: 16x;

        a {
          color: $colour-primary;
        }

        @media screen and (max-height: $mobile-height-md) {
          font-size: 16px;
        }
    }

    .checkbox {
        border: solid 1px $colour-light-grey;
        border-radius: 5px;
        display: block;
        height: 20px;
        width: 20px;
        margin-right: $spacing-sm;

        &.checked {
            &:after {
                content: '\2714\0020';
                width: 20px;
                text-align: center;
                display: block;
                font-size: 20px;
                line-height: 20px;
                color: $colour-primary;
            }
        }
    }

    input[type=checkbox] {
      position: absolute;
      left: -9999px;
    }

    input:focus + .checkbox {
      border: solid 1px $colour-dark-grey;
    }
}

  fieldset.dob {
    border: none;
    padding: 0;
    margin: 0;
    display: flex;

    input {
      margin-right: $spacing-sm;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.verify {
  display: flex;
  position: fixed;
  bottom: $spacing-md;
  text-align: center;
  font-size: 14px;
  width: 100%;
  // transition: opacity 0.25s ease-out, bottom 0.4s ease-out;
  text-decoration: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  @media screen and (max-height: $mobile-height-lg) {
    position: relative;
  }

  &.leaving {
    opacity: 0;
    bottom: $spacing-xxxl;
  }

  h3 {
    font-size: 16px;
    color: $colour-primary;
  }

  img {
    width: 20px;
    margin-left: $spacing-sm;
    position: relative;
    animation: arrowScroll 2s infinite;
  }

  @media screen and (max-height: $mobile-height-md) {
    bottom: $spacing-sm;
    font-size: 12px;

    img {
      width: 20px;
    }
  }
  
  @media screen and (min-width: $container-sm) {
    position: absolute;
    left: calc(50% + 160px);
    top: 400px;
    bottom: auto;
    width: 20px;
    height: 120px;
    transform: rotate(-90deg);

    h3 {
      display: none;
    }

    img {
      width: 50px;
    }
  }
}
