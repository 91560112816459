@import "../../variables.scss";

input[type=range] {
  -webkit-appearance: none;
  width: 261px;
  margin: $spacing-lg auto;
  display: block;
  position: relative;
  top: 60px;
  // transition: top 1s ease-out;
  outline: none;

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: -5px;
    height: 2px;
    width: 5px;
    background-color: $colour-primary-gradient;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 19px;
    width: 19px;
    border-radius: 10px;
    background: $colour-primary;
    cursor: pointer;
    margin-top: -7px;
  }

  &::-webkit-slider-runnable-track {
    height: 3px;
    -webkit-appearance: none;
    background-color: transparent;
    margin-top: -1px;
  }

  @media screen and (max-height: $mobile-height-sm) {
    width: 216px;
  }
}

.step.active input[type=range],
.step.previous input[type=range] {
  top: 0;
}

.notches {
  display: flex;
  justify-content: space-between;
  margin: 0;
  position: absolute;
  width: 261px;
  left: 50%;
  transform: translateX(-50%) translateY(60px);
  // transition: transform 1s ease-out;
  bottom: -5px;
  
  @media screen and (max-height: $mobile-height-sm) {
    width: 216px;
  }

  .notch {
    height: 12px;
    width: 12px;
    border-radius: 12px;
    background-color: #848484;
    position: relative;
    z-index: 20;
    cursor: pointer;

    &.previous {
      background-color: $colour-primary-gradient;
    }

    &.current {
      z-index: -1;
    }
  }
}

.step.active .notches,
.step.previous .notches {
  transform: translateX(-50%) translateY(0px);
}