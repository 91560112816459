@import "../../../variables";

.postcode-step {
    .postcode-image {
        margin: 0 auto;
        display: block;
        width: 142px;
        // transition: top 1s ease-out;
        top: 150px;
        position: relative;
        z-index: 1;
    }

    .postcode-input {
        margin: $spacing-lg 0;
        width: 100%;
        padding: $spacing-sm;
        box-sizing: border-box;
        font-size: 14px;
        font-family: $font-default;
        border-radius: 4px;
        border: solid 1px $colour-mid-grey;
    }

    .validating-postcode {
        margin: 0;
    }

    .invalid-postcode {
        margin: 0;
        color: $colour-error;
        font-weight: 300;
    }
}

.step.active .postcode-step .postcode-image {
    top: 0 !important;
}
