@import "../../../variables";


.cholesterol-step {
  .field-card {
    overflow-x: hidden;
  }

  .cholesterol-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .cholesterol-item {
      width: 100%;
      margin: $spacing $spacing $spacing-xxs $spacing;
    }
    
    > p {
      text-align: left;
      width: 100%;
      margin: $spacing 0 0 0;
      font-size: 13px;
    }
  }

  .cholesterol-status {
    position: relative;
    float: left;
    width: 48%;
    margin-right: 2%;
    // transition: margin-left 0.75s ease-out;

    > p {
      margin: 0;

      strong {
        font-family: $font-bold;
      }
    }
  }

  .cholesterol-values {
    float: left;
    width: 48%;

    .back {
      font-size: 13px;
      color: $colour-primary;
      font-family: $font-bold;
      padding-left: $spacing;
      background-image: url("../../../../public/assets/back.png");
      background-repeat: no-repeat;
      background-size: $spacing-xs;
      background-position: left center;
    }
  }

  &.with-values {
    .cholesterol-status {
      margin-left: -100%;
    }
  }

  article {
    width: 200%;
    box-sizing: border-box;

    > p {
      margin: $spacing-sm $spacing;

      &:nth-of-type(2) {
        margin-top: $spacing-xl;
      }

      @media screen and (max-height: $mobile-height-md) {
        font-size: 14px;
        margin: $spacing-sm 0;

        &:nth-of-type(2) {
          margin-top: $spacing-xl;
        }
      }
    }
  }
}
