@import "../../variables";

.welcome-desktop {
    position: relative;
    width: 100%;
    background-color: $colour-primary;

    .content {
        display: flex;
        align-items: center;
        max-width: $container-xs;
        margin: 0 auto;
        padding: $spacing-xxl;

        .logo {
            display: block;
            margin: 0 auto;
            max-width: $container-mobile;
        }

        h2 {
            text-align: center;
            font-size: 24px;
            margin: 0 0 $spacing-xxxl 0;
            font-weight: 400;
            color: $colour-primary-darker;
        }
    }
    
    @media screen and (max-width: $container-sm) {
        display: none;
    }
}
