@import "../../variables.scss";

.accessible-test {
    display: block;
    max-width: $container-xxs;
    margin: $spacing-xl auto;

    fieldset {
        border: none;
        border-bottom: solid 1px $colour-light-grey;
        display: flex;
        flex-direction: column;
        padding: $spacing-xl 0;

        &:last-of-type {
            border-bottom: none;
        }

        label {
            display: block;
            margin: $spacing-xs 0;
            font-family: $font-bold;
            color: $colour-primary;
            font-size: 18px;
        }

        input {
            padding: $spacing-sm;
            flex: 1;
            box-sizing: border-box;
            font-family: $font-default;
            border-radius: 4px;
            border: solid 1px $colour-mid-grey;
            background-color: $colour-white;
            font-size: 18px;
        }

        .radio-group {
            display: flex;
            flex-direction: column;
            font-size: 18px;

            .radio-label {
                margin: $spacing-xxs 0;
                color: $colour-black;
                font-family: $font-default;
            }
            
            &.unit-group {
                flex-direction: row;
                margin-bottom: $spacing-xs;
                
                .radio-label {
                    margin-right: $spacing;
                }
            }
        }

        .multi-unit {
            display: flex;
            flex-direction: row;

            label {
                display: flex;
                flex-direction: column;
                font-family: $font-default;
                color: $colour-black;

                input { 
                    margin-top: $spacing-sm;
                    margin-right: $spacing-lg;
                }
            }
        }
    }
}