@import "../../variables";

.info-modal {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.7);
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 200;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.4s ease-out;
    font-weight: 400;
    color: $colour-white;

    iframe {
        max-width: 428px;
        height: 240px;
        width: 100%;
        display: block;
        border: none;
        margin: 0 auto $spacing-lg auto;
    }

    &.open {
        pointer-events: all;
        opacity: 1;
    }

    .modal {
        border-radius: 10px;
        background: $colour-white;
        border: solid 1px $colour-primary;
        padding: $spacing-lg $spacing-xl;
        color: $colour-black;
        width: 90%;
        max-width: 680px;
        max-height: 75vh;
        overflow-y: auto;
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);

        &.danger {
            background: $colour-error;
        }

        .close-modal {
            position: absolute;
            top: $spacing;
            right: $spacing;
            font-weight: 300;
            font-size: 24px;
            line-height: 20px;
            color: $colour-primary;
            cursor: pointer;
        }

        h2 {
            border-bottom: solid 1px $colour-primary;
            padding: 0 0 $spacing 0;
            font-size: 16px;
            line-height: 20px;
            font-family: $font-bold;
            text-align: left;

            .help-icon {
                color: $colour-white;
                background-color: $colour-primary;
                padding: $spacing-xs;
                border-radius: $spacing-lg;
                margin-right: $spacing-sm;
                display: inline-block;
                font-size: 16px;
                line-height: 6px;
                font-family: $font-bold;
                float: left;
            }

            .warning-icon {
                float: left;
                background-image: url("../../../public/assets/alert_black.png");
                height: 20px;
                width: 20px;
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: center;
                margin-right: $spacing-sm;
            }
        }

        .content {
            font-size: 15px;
            line-height: 20px;
            text-align: left;
            padding-top: $spacing-sm;

            p,
            ul {
                font-size: 15px;
                color: $colour-black;
                margin-top: 0;
                margin-bottom: $spacing;
            }

            a {
                color: $colour-white;
            }

            table {
                max-width: $container-mobile;
                margin: 0 auto;

                td {
                    padding: $spacing-xxs $spacing-sm;
                    text-align: center;

                    p {
                        margin: 0;
                        text-align: left;
                    }
                }
            }
        }
    }

    video {
        width: 100%;
        max-width: $container-mobile;
        margin: 0 auto;
        display: block;
    }
}
