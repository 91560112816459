@import "../../../variables";

.age-gender-step {
  h3 {
    font-size: 60px;
    color: $colour-primary;
    text-align: center;
    margin: $spacing-sm 0;
    font-weight: 400;
    pointer-events: none;
  }

  .gender-step {
    display: flex;
    justify-content: center;

    .gender-selector {
      cursor: pointer;

      img {
        background-color: $colour-white;
        border-radius: 15px;
        margin: 0 $spacing-sm;
        border: solid 3px $colour-white;
        // transition: border-color 0.2s linear, top 1s ease-out;
        position: relative;
        width: 64px;
        padding: $spacing $spacing 0 $spacing;
        top: 40px;

        @media screen and (max-height: $mobile-height-md) {
          width: 48px;
        }

        &.selected {
          border: solid 3px $colour-primary;
        }
      }

      &:hover {
        img {
          opacity: 0.8;
        }
      }

      p {
        margin: 0;
        font-family: $font-bold;
        text-align: center;
      }
    }
  }
}

.step.previous .gender-step .gender-selector img,
.step.active .gender-step .gender-selector img {
  top: 0 !important;
}
