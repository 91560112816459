@import "../../variables.scss";

.missing-values {
    max-width: $container-xs;
    margin: $spacing-xl auto;
    text-align: center;
    padding: $spacing;
    box-sizing: border-box;
    // min-height: calc(100vh - 48px);
    display: flex;
    align-items: center;
    width: 100%;

    &.in-test {
        margin: 0 auto;
        padding: 0;
    }

    div {
        width: 100%;
        max-width: $container-xxs;
        margin: 0 auto;
        padding-bottom: 100px;
    }

    h3 {
        font-weight: 300;
        text-align: center;
        margin: $spacing-lg;
        font-size: 22px;
        width: auto;
        max-width: none;
        margin-bottom: $spacing-sm;
    }

    p {
        strong {
            color: $colour-error;
        }
    }

    ul {
        display: flex;
        justify-content: center;
        margin: $spacing-xl auto;
        padding: 0;
        flex-wrap: wrap;
        flex-direction: column;
        max-width: $container-mobile;
        width: 100%;

        a {
            text-decoration: none;
            color: $colour-black;
            margin: $spacing-xs auto;
            width: 66%;
            position: relative;

            &:before {
                content: " ";
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 10px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7.5px 10px 7.5px 0;
                border-color: transparent $colour-error transparent transparent;
            }

            li {
                list-style-type: none;
                padding: $spacing-xs;
                background-color: $colour-white;
                border: solid 1px $colour-error;
                border-radius: 4px;
                font-size: 14px;
                transition: background-color 0.2s linear;
                text-align: center;

                &:hover {
                    background-color: $colour-grey;
                }
            }
        }
    }

    p {
        margin: $spacing $spacing-xxl;
    }

    .button {
        max-width: 200px;
        line-height: normal;

        &.wide {
            max-width: none;
            font-size: 16px;
            width: 240px;
            background-image: url("../../../public/assets/external.svg");
            background-repeat: no-repeat;
            background-size: 16px;
            background-position: 220px center;
        }
    }

    .info-modal .modal .content {
        p {
            margin: 0 0 $spacing-sm 0;
        }

        strong {
            font-family: $font-bold;
        }

        input {
            text-transform: uppercase;
            text-align: center;
            font-size: 18px;
            font-family: $font-bold;
            letter-spacing: 2px;
        }
    }

    .blood-test-status {
        padding: $spacing;
        border: solid 1px $colour-grey;
        max-width: 240px;
        margin: $spacing-xl auto;

        h4 {
            margin-top: 0;
        }

        ul {
            text-align: left;
            padding: 0 0 0 $spacing-xl;
            width: 175px;
            margin: 0 auto;
            overflow-y: hidden;
            overflow-x: visible;

            li {
                margin: 0 0 $spacing-sm 0;
                list-style-type: none;
                position: relative;

                &:before {
                    content: " ";
                    width: 10px;
                    height: 10px;
                    background-color: $colour-white;
                    border: solid 3px $colour-mid-grey;
                    border-radius: 10px;
                    display: block;
                    position: absolute;
                    left: -25px;
                    top: 0px;
                    z-index: 4;
                }

                &:after {
                    content: " ";
                    width: 3px;
                    height: 100px;
                    background-color: $colour-mid-grey;
                    display: block;
                    position: absolute;
                    left: -18.5px;
                    top: auto;
                    bottom: calc(100% - 10px);
                    z-index: 2;
                }

                &:first-of-type {
                    &:after {
                        display: none;
                    }
                }

                &.checked {
                    &:before {
                        background-image: url("../../../public/assets/white_tick.png");
                        background-color: $colour-affirmative;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 10px;
                        height: 16px;
                        width: 16px;
                        border: none;
                    }

                    & ~ .checked,
                    & ~ .error {
                        &:after {
                            background-color: $colour-affirmative;
                            z-index: 3;
                        }
                    }
                }

                &.error {
                    &:before {
                        background-image: url("../../../public/assets/close.png");
                        background-color: $colour-error;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 10px;
                        height: 16px;
                        width: 16px;
                        border: none;
                    }
                }

                &.not-checked {
                    color: $colour-mid-grey;
                }
            }
        }
    }
}

.error-instructions {
    text-align: left;
    p {
        margin: $spacing 0;
    }
}
