@import "../../variables";

.risk {
  display: none;
  background: -moz-linear-gradient(top, $colour-white 30%, $colour-secondary-gradient 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, $colour-white 30%, $colour-secondary-gradient 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, $colour-white 30%, $colour-secondary-gradient 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  position: relative;
  z-index: 1;
  // transition: opacity 0.25s ease-out;
  margin-bottom: $spacing-xl;

  @media screen and (max-width: $container-xs) { 
    position: fixed;
  }  

  &.leaving {
    opacity: 0;
  }

  .risk-container {
    padding: $spacing $spacing-xxl;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: $container-xs;
    margin: 0 auto;

    p {
      margin-right: $spacing-lg;
      font-size: 14px;
    }

    .risk-value {
      height: 73px;
      width: 73px;
      flex: 0 0 73px;
      border-radius: 38px;
      background-color: $colour-white;
      border: solid 2px $colour-primary;
      color: $colour-primary;
      font-size: 22px;
      font-family: $font-bold;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 20px 0 rgba(146, 146, 146, 0.5);

      &.low {
        border-color: $colour-risk-low;
        color: $colour-white;
        background-color: $colour-risk-low;
      }

      &.med {
        border-color: $colour-risk-med;
        color: $colour-white;
        background-color: $colour-risk-med;
      }

      &.high {
        border-color: $colour-risk-high;
        color: $colour-white;
        background-color: $colour-risk-high;
      }
    }


    @media screen and (max-height: $mobile-height-lg) {
      padding: $spacing-sm $spacing-lg;
    }

    @media screen and (max-height: $mobile-height-md) {
      p {
        font-size: 12px;
        margin-right: $spacing-sm;
      }

      .risk-value {
        height: 60px;
        width: 60px;
        flex: 0 0 60px;
        font-size: 16px;
      }
    ;
    }
  }
}

