@import "../../variables.scss";

.faqs-container {
    max-width: $container-sm;
    margin: 0 auto;
    padding: $spacing-lg;

    h1 {
        color: $colour-primary;
        font-weight: 400;
        text-align: center;

        img {
            margin-bottom: $spacing;
        }
    }

    dl {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        dd {
            flex: 60%;
            margin: 0;
            padding: $spacing;
            box-sizing: border-box;
        }

        dt {
            flex: 40%;
            color: $colour-primary;
            font-family: $font-bold;
            margin: 0;
            padding: $spacing;
            box-sizing: border-box;
        }

        @media screen and (max-width: $container-sm) {
            dd, dt {
                flex: 100%;
            }

            dt {
                padding: $spacing-lg $spacing $spacing-xs $spacing;
            }

            dd {
                padding: $spacing-xs $spacing $spacing-lg $spacing;
            }
        }
    }
}