
@import "../../variables";
.tutorial-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000000000;
    background-color: rgba(0,0,0,0.4);
    // transition: opacity 0.25s linear;

    &.dismissed {
      opacity: 0;
      pointer-events: none;
    }
  
    .tutorial-message {
      background-color: $colour-white;
      position: absolute;
      padding: $spacing-sm;
      width: 180px;
      text-align: center;
      border-radius: 5px;
      box-shadow: 0px 0px 5px rgba(0,0,0,0.8);
      margin: 0;
      font-size: 14px;

      p {
        margin: $spacing-xs 0;
      }

      &:before,
      &:after {
        content: ' ';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
      }
  
      &.bottom {
        margin-top: 15px;

        &:after {
            border-width: 0 5px 10px 5px;
            border-color: transparent transparent $colour-white transparent;
            left: 50%;
            top: -10px;
        }
        
        &:before {
            border-width: 0 6px 11px 6px;
            border-color: transparent transparent rgba(0,0,0,0.8) transparent;
            left: calc(50% - 1px);
            top: -11px;
        }

        &.bottom-right {
          transform: translateX(-100%);

          &:after { right: 34px; left: auto; }
          &:before { right: 33px; left: auto; }

          @media screen and (max-width: $container-xs) {
            &:after { right: 27px; left: auto; }
            &:before { right: 26px; left: auto; }
          }
        }
      }

      &.top {
        transform: translateY(-100%);
        margin-top: -15px;
        
        &:after {
            border-width: 10px 5px 0 5px;
            border-color: $colour-white transparent transparent transparent;
            left: 50%;
            bottom: -10px;
        }
        
        &:before {
            border-width: 11px 6px 0 6px;
            border-color: rgba(0,0,0,0.8) transparent transparent transparent;
            left: calc(50% - 1px);
            bottom: -11px;
        }
      }

      &.left {
        transform: translateY(-50%);

        &:after {
            border-width: 5px 0 5px 10px;
            border-color:  transparent transparent transparent $colour-white;
            right: -10px;
            top: 50%;
        }
        
        &:before {
            border-width: 6px 0 6px 11px;
            border-color: transparent transparent transparent rgba(0,0,0,0.8);
            right: -11px;
            top: calc(50% - 1px);
        }
      }
    }

    button {
        background-color: $colour-primary;
        color: $colour-white;
        border: none;
        font-family: $font-default;
        font-family: $font-bold;
        font-size: 16px;
        border-radius: 5px;
    }
  }