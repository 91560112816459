@import "../../../variables.scss";

.dementia-step {
    font-size: 14px;

    .dementia-image {
        margin: $spacing-xxl auto 0 auto;
        display: block;
        // transition: top 1s ease-out;
        top: 150px;
        position: relative;
        z-index: 1;

        
        @media screen and (max-height: $mobile-height-sm) {
            width: 80px;
        }
    }
}

.step.active .dementia-step .dementia-image {
    top: 0 !important;
}
  