@import "../../variables";

.promo-modal {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.7);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 200;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s ease-out;

  &.open {
    pointer-events: all;
    opacity: 1;
  }

  .modal {
    border-radius: 10px;
    background: $colour-primary;
    padding: $spacing-xxxl $spacing-xl $spacing $spacing-xl;
    color: $colour-white;
    width: 90%;
    max-width: 680px;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    .close-modal {
      position: absolute;
      top: $spacing-lg;
      right: $spacing;
      font-weight: 300;
      font-size: 32px;
      line-height: 14px;
      z-index: 2;
      cursor: pointer;
    }

    h2 {
      margin: 0;
      padding: $spacing-lg $spacing-xxxl*2 $spacing-lg $spacing-xl;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 20px;
      font-family: $font-bold;
      background-color: $colour-primary-mid;
      background-image: url("../../../public/assets/heart-rate.png");
      background-repeat: no-repeat;
      background-position: 75% center;
      background-size: 120px;
      padding-right: 150px;
    }

    .content {
      padding: $spacing-xl 0 0 0;
      font-size: 14px;
      line-height: 20px;
    }

    .button {
      background-image: linear-gradient(to bottom, #7a1a1a, #550000);
      font-size: 16px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
}

.promo-modal-frame {
  position: fixed;
  width: 100vw;
  height: 0;
  top: 100vh;
  left: 0;
  background-color: $colour-white;
  z-index: 2002;
  opacity: 0;
  pointer-events: none;
  transition: top 0.5s ease-out;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  &.visible {
    opacity: 1;
    height: 100vh;
    top: 0;
  }
}
