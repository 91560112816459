@import "../../variables";

.blood-pressure-tests {
  transition: padding-top 0.5s ease-out;

  &.leaving {
    padding-top: 100vh;

    header,
    header.close {
      top: 100vh;
    }
  }

  header {
    background: $colour-primary;
    color: $colour-white;
    padding: $spacing $spacing-xl $spacing $spacing;
    width: 100vw;
    box-sizing: border-box;
    transition: top 0.5s ease-out;
    font-size: 24px;
    font-weight: normal;
    z-index: 2002;
    position: relative;
    top: 0px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: $container-sm;
    margin: 0 auto;

    .close {
      display: block;
    }

    > div {
      max-width: $container;
      box-sizing: border-box;
      padding: 0 $spacing-md;
    }
  }

  .spacer {
    padding-top: calc(30vh + 18px);
  }

  .pharmacy-map {
    height: 30vh;
    position: relative;
    z-index: 2001;
    position: fixed;
    top: 42px;
    width: 100vw;

  }

  .input-field {
    margin: 0;
    padding: $spacing-xl;
    background-color: $colour-offwhite;

    div {
      max-width: $container-xs;
      margin: 0 auto;
    }
  }

  .bp-content {
    max-width: $container-xs;
    margin: $spacing-lg auto;
    width: 80%;
  }

  .pharmacies {
    margin-top: $spacing-xxl;
    
    .pharmacy {
      margin: 0 $spacing-xs;
      padding: $spacing-xs $spacing-lg $spacing-xs $spacing-xs;
      border-bottom: solid 1px $colour-grey;
      background-color: $colour-almostwhite;
      cursor: pointer;

      section {
        max-width: $container-xs;
        margin: 0 auto;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }

      .details {
        flex: 3;

        h3 {
          font-size: 18px;
          margin-bottom: 0;
          color: $colour-primary;
          font-weight: normal;
        }

        .address {
          font-size: 14px;
          margin-top: $spacing-xxs;
        }

      }

      .distance {
        flex: 1;
        text-align: right;

        p {
          font-size: 13px;
        }
      }

      .expando {
        width: 40px;
        background-image: url('../../../public/assets/next-step.png');
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: right 18px;
      }

      .more-details {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.4s ease-out;
        width: 100%;

        > div {
          display: flex;
          padding-bottom: $spacing-lg;

          > strong {
            width: 100px;
          }

          > div {
            display: flex;
            flex-wrap: wrap;
            max-width: 320px;
            font-size: 13px;
            line-height: 20px;

            > span {
              flex: 50%;

              &:nth-of-type(2n) {
                align-content: flex-end;
              }
            }
          }
        }
      }

      &.selected {
        background-color: $colour-white;

        .expando {
          background-image: url(../../../public/assets/prev-step.png);
        }

        .more-details {
          max-height: 400px;
        }
      }
    }
  }

  .button {
    font-size: 14px;
    line-height: normal;
    width: 80%;
    max-width: $container-xs;
  }
}
