@import "../../variables.scss";

.questionnaire-link {
    width: calc(100% - #{$spacing-lg} - #{$spacing-lg});
    max-width: $container-xs;
    margin: $spacing auto;
    padding: $spacing;
    border: solid 1px $colour-primary;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    background-color: $colour-white;
    box-shadow: 1px 1px 5px $colour-mid-grey;
    color: $colour-primary;
    font-weight: bold;
    font-size: 18px;
    top: 0px;
    transition: top 0.2s linear;
    z-index: 200;
    text-align: center;
  
    &.sticky {
      position: fixed;
      left: 0;
      top: 70px;
      width: calc(100vw - 17px);
      max-width: none;
      padding: $spacing;
      border-radius: 0;
      box-shadow: none;
    }
  
    section {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: $container-xs;
      margin: 0 auto;
    }
  
    .button {
      margin: 0 0 0 $spacing;
    }
  
    @media screen and (max-width: $container-sm) {
      font-size: 16px;
  
      &.sticky {
        top: 54px;
      }
  
      .button {
        width: 45px;
        font-size: 16px;
        padding: $spacing-xs;
      }
    }
  
    @media screen and (max-width: $container-sm) {
      &.sticky {
        width: 100vw;
      }
    }
  }
  