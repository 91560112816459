@import "../../variables";
@import "../home/Home";

.about-container {
  @extend .home-container;
  max-width: $container;
  position: relative;
  margin: 0 auto;
  padding-bottom: 100px;

  .intro {
    padding: $spacing-xxl;
    font-size: 18px;
    line-height: 32px;

    h2 {
      font-size: 32px;
      color: $colour-primary;
      font-weight: 400;
    }

    .button {
      margin-top: $spacing-xxl;
    }
  }

  @media screen and (max-width: $container-sm) {
    .intro {
      font-size: 18px;
      line-height: 28px;
    }

    article {
      flex-direction: column;
      position: relative;
      padding: $spacing-md $spacing-xxl;
      align-items: center;
      
      &:nth-of-type(2n+1) { flex-direction: column-reverse; }

      > * {
        width: auto;
      }

      .content {
        padding: 0;
      }

      .oval,
      .oval.right {
        width: $container-mobile;
        height: $container-mobile;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
      }

      img {
        margin: 0 auto;
        max-width: 80%;
        padding: 0;
        object-fit: contain;
      }

      .heart-icons {
        margin: 0 auto;
      }

      &:last-of-type {
        padding-bottom: 0;
      }
    }

    .do-the-test {
      margin-top: 0;

      h2 {
        font-size: 28px;
        font-weight: 400;
      }
    }
  }
}
