@import "../../variables";

.option-toggle {
  width: 75px;
  height: 75px;
  background-color: $colour-white;
  border: solid 1px $colour-mid-grey;
  color: $colour-darker-grey;
  margin: $spacing-xxs;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  word-break: break-word;
  font-size: 14px;
  border-radius: 10px;
  cursor: pointer;
  transition: color 0.2s linear, border-color 0.2s linear;//, top 1s ease-out;
  position: relative;

  &:hover {
    color: $colour-black;
    border-color: $colour-dark-grey;
  }

  &.selected {
    border-color: $colour-primary;
    border-width: 2px;
    margin: calc(#{$spacing-xxs} - 2px);
    color: $colour-black;
    font-family: $font-bold;
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  .help {
    position: absolute;
    top: -10px;
    right: -10px;
    background-image: url("../../../public/assets/help.png");
    background-repeat: no-repeat;
    background-position: center;
    padding: 8px 13px;
    color: #ffffff;
    text-indent: -9999px;
    font-family: $font-bold;
    line-height: 10px;
    border-radius: 15px;
  }

  &:nth-of-type(1) { top: 40px; }
  &:nth-of-type(2) { top: 80px; }
  &:nth-of-type(3) { top: 120px; }
  &:nth-of-type(4) { top: 160px; }
  &:nth-of-type(5) { top: 200px; }
  &:nth-of-type(6) { top: 240px; }
  &:nth-of-type(7) { top: 280px; }
  &:nth-of-type(8) { top: 320px; }
  &:nth-of-type(9) { top: 360px; }

  @media screen and (max-height: $mobile-height-sm) {
    height: 60px;
    width: 60px;
    font-size: 12px;
  }
}



.step.previous .option-toggle,
.step.active .option-toggle {
  top: 0 !important;
}
