@import "../../variables";

.preferences-container {
  margin: 0 auto;
  max-width: 250px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - #{$spacing-xxl});
  flex-direction: column;

  h2 {
    font-weight: 400;
    color: $colour-primary;
    margin-bottom: $spacing-xxl;
    text-align: center;
  }

  form {
    color: $colour-primary;
    width: 100%;

    fieldset.for-checkbox {
        border: none;
        padding: 0;
        margin: $spacing-lg 0;
        display: flex;
        cursor: pointer;

        label {
            cursor: pointer;
            width: 200px;
        }
    
        .checkbox {
            border: solid 1px $colour-light-grey;
            border-radius: 5px;
            display: block;
            height: 20px;
            width: 20px;
            margin-right: $spacing-sm;

            &.checked {
                &:after {
                    content: '\2714\0020';
                    width: 20px;
                    text-align: center;
                    display: block;
                    font-size: 20px;
                    line-height: 20px;
                }
            }
        }
    }
    
    label {
        color: $colour-primary;
        font-family: $font-bold;
        display: inline-block;
        font-size: 16px;
    }

    select {
        padding: $spacing-xs;
        width: 100%;
        margin: $spacing-lg 0;
        border: solid 1px $colour-light-grey;
        border-radius: 5px;
    }
  }

  p {
      font-size: 16px;
      color: $colour-dark-grey;
  }
}

.continue {
  display: flex;
  position: fixed;
  bottom: $spacing-md;
  text-align: center;
  font-size: 14px;
  width: 100%;
  // transition: opacity 0.25s ease-out, bottom 0.4s ease-out;
  text-decoration: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  &.leaving {
    opacity: 0;
    bottom: $spacing-xxxl;
  }

  h3 {
    font-size: 16px;
    color: $colour-primary;
  }

  img {
    width: 20px;
    margin-left: $spacing-sm;
    position: relative;
    animation: arrowScroll 2s infinite;
  }

  @media screen and (max-height: $mobile-height-md) {
    bottom: $spacing-sm;
    font-size: 12px;

    img {
      width: 20px;
    }
  }

  @media screen and (min-width: $container-sm) {
    position: absolute;
    left: calc(50% + 160px);
    top: 400px;
    bottom: auto;
    width: 20px;
    height: 120px;
    transform: rotate(-90deg);

    h3 {
      display: none;
    }

    img {
      width: 50px;
    }
  }
}
