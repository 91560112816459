@import "../../variables";

.input-field {
  border: none;
  padding: 0;
  margin: $spacing-lg 0;

  label {
    font-size: 14px;
    color: $colour-black;
    font-family: $font-bold;
    display: block;
    margin-bottom: $spacing-xxs;
  }

  span.error {
    font-size: 12px;
    margin-bottom: $spacing-xs;
    display: inline-block;
    color: $colour-error;
  }

  &.invalid-date {
    input {
      border-color: $colour-error;
      border-width: 2px;
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input,
    > select {
      padding: $spacing-sm;
      flex: 1;
      box-sizing: border-box;
      font-family: $font-default;
      border-radius: 4px;
      border: solid 1px $colour-mid-grey;
      background-color: $colour-white;
      font-size: 16px;

      &.invalid {
        border-color: $colour-error;
        border-width: 2px;
      }
    }

    select {
      max-width: $container-mobile;
    }

    .button {
      width: auto;
      font-size: 14px;
      margin: 0 0 0 $spacing-sm;
      padding: $spacing-xs $spacing;
      border-bottom: none;
      background-color: $colour-black;

      &:after {
        display: none;
      }
    }

    p {
      margin: 0;
    }
  }
}
