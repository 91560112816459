@import "../../variables";

.step {
    position: relative;
    width: 100%;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    padding: $spacing-xxl 0;

    @media screen and (min-width: $container-xs) {
        height: auto;
    }

    > *:last-child {
        margin-bottom: $spacing-lg !important;
    }

    &.align-top {
        justify-content: flex-start;
    }

    @media screen and (max-height: $mobile-height-sm) and (max-width: $container-xs) {
        padding-top: 90px;
    }

    section {
        opacity: 0;
        // transition: opacity 1s ease-out, top 0.7s ease-out, left 1s ease-out;
        position: relative;
        top: 40vh;
        width: 320px;
        margin: 0 auto;
        display: none;

        @media screen and (max-height: $mobile-height-lg) {
            margin-top: 0;
        }

        @media screen and (min-width: $container-xs) {
            width: 296px;
        }

        @media screen and (min-width: $container-sm) {
            width: 420px;
        }
    }

    .button {
        margin: $spacing-xs auto;
        width: 100px;
        display: none;

        &.wide {
            font-size: 16px;
            width: 240px;
            background-image: url("../../../public/assets/external.svg");
            background-repeat: no-repeat;
            background-size: 16px;
            background-position: 220px center;
        }
    }

    .last-step-description {
        text-align: center;
        margin: 0;
        line-height: 22px;
        padding: $spacing-sm $spacing-xxl;
    }

    &.active {
        display: flex;
        pointer-events: all;
        padding-bottom: $spacing-xxxxl;

        section {
            top: 0;
            opacity: 1;
            display: block;
        }

        .button {
            display: block;
        }
    }

    &.previous {
        display: none;

        section {
            top: -20vh;
            opacity: 0;
        }
    }

    @media screen and (min-width: $container-xs) {
        section {
            position: relative;
            top: 40px;
            left: 20vw;
        }

        &.active {
            section {
                top: 40px;
                left: 0;
                padding-bottom: 40px;
            }
        }

        &.previous {
            section {
                top: 40px;
                left: -10vw;
            }
        }
    }
}
