@import "../../variables";

.test-container {
    min-height: calc(100vh - 275px);
    position: relative;

    @media screen and (max-height: $mobile-height-sm) {
        min-height: 580px;
        overflow: initial;
    }
}

.test-steps {
    @media screen and (max-width: $container-xs) {
        padding-top: 0;
    }

    & > {
        .forward,
        .back {
            position: absolute;
            left: calc(50% + 160px);
            top: 320px;

            .disabled-link {
                pointer-events: none;
                opacity: 0.2;
            }

            img {
                height: 37px;
                cursor: pointer;
            }

            @media screen and (max-width: $container-xs) {
                display: none;
            }

            @media screen and (min-width: $container-sm) {
                left: calc(50% + 250px);
            }
        }

        .back {
            left: calc(50% - 180px);

            @media screen and (min-width: $container-sm) {
                left: calc(50% - 250px);
            }
        }
    }
}

.previous-step {
    display: flex;
    position: fixed;
    bottom: $spacing-md;
    justify-content: center;
    width: 30vw;
    z-index: 20;

    .disabled-link {
        pointer-events: none;
        opacity: 0.2;
    }

    @media screen and (min-width: $container-xs) {
        display: none;
    }

    a {
        color: $colour-mid-grey;
        text-decoration: none;
        display: flex;
        font-size: 14px;

        img {
            width: 20px;
            margin-left: $spacing-sm;
            position: relative;
            align-self: center;
            transform: rotate(180deg);
            filter: grayscale(100%);
        }
    }
}

.next-step {
    display: flex;
    position: fixed;
    bottom: $spacing-md;
    text-align: center;
    font-size: 14px;
    width: 100%;
    // transition: opacity 0.25s ease-out, bottom 0.4s ease-out;
    text-decoration: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    .disabled-link {
        pointer-events: none;
        opacity: 0.2;
    }

    p {
        max-width: 200px;
        margin: $spacing-md auto;
    }

    span,
    a {
        display: flex;
        text-decoration: none;
    }

    &.leaving {
        opacity: 0;
        bottom: $spacing-xxxl;
    }

    h3 {
        font-size: 16px;
        color: $colour-primary;

        @media screen and (min-width: $container-xs) {
            display: none;
        }
    }

    img {
        width: 20px;
        margin-left: $spacing-sm;
        position: relative;
        animation: arrowScroll 2s infinite;
        align-self: center;

        @media screen and (min-width: $container-xs) {
            display: none;
        }
    }

    @media screen and (max-height: $mobile-height-md) {
        bottom: $spacing-sm;
        font-size: 12px;

        img {
            width: 20px;
        }
    }

    @media screen and (max-height: $mobile-height-sm) {
        p {
            display: none;
        }
    }

    @media screen and (min-width: $container-xs) {
        bottom: auto;
        top: 760px;
    }
}

.step-navigation {
    position: fixed;
    right: $spacing-xl;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    pointer-events: none;
    // transition: opacity 0.25s ease-out, top 0.4s ease-out;
    z-index: 1; // this may have to revert to 2... let's see

    &.leaving {
        opacity: 0;
    }

    @media screen and (max-height: $mobile-height-sm) {
        top: 250px;
        right: $spacing;
    }

    .step-nav-item {
        height: 12px;
        width: 12px;
        border-radius: 8px;
        background-color: $colour-mid-grey;
        margin-bottom: 12px;
        // transition: background-color 0.2s ease-out;
        position: relative;

        &.nav-disabled {
            pointer-events: none;
            opacity: 0;
        }

        &.nav-removed {
            display: none;
        }

        span {
            opacity: 0;
            pointer-events: none;
            transform: translateX(-50%);
            padding: $spacing-xxs;
            border: solid 1px $colour-primary-shadow;
            color: $colour-primary;
            box-shadow: 0 0 3px $colour-mid-grey;
            border-radius: 5px;
            position: absolute;
            top: -30px;
            left: $spacing-xs;
            display: block;
            transition: all 0.2s linear;
            font-size: 11px;
            background-color: $colour-white;
            width: 120px;
            text-align: center;
            display: block;
            z-index: 5;

            &:before {
                content: " ";
                display: block;
                position: absolute;
                bottom: -7.5px;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7.5px 3.75px 0 3.75px;
                border-color: $colour-primary transparent transparent
                    transparent;
            }

            &:after {
                content: " ";
                display: block;
                position: absolute;
                bottom: -7px;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7.5px 3.75px 0 3.75px;
                border-color: $colour-white transparent transparent transparent;
            }
        }

        &:hover {
            span {
                display: block;
                top: -40px;
                opacity: 1;
            }
        }

        @media screen and (max-width: $container-xs) {
            span {
                left: -100px;
                top: $spacing-xs;
                transform: translateY(-50%);

                &:before {
                    bottom: auto;
                    top: 50%;
                    transform: translateY(-50%);
                    left: auto;
                    right: -7.5px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 3.75px 0 3.75px 7.5px;
                    border-color: transparent transparent transparent
                        $colour-primary;
                }

                &:after {
                    bottom: auto;
                    top: 50%;
                    transform: translateY(-50%);
                    left: auto;
                    right: -7px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 3.75px 0 3.75px 7.5px;
                    border-color: transparent transparent transparent
                        $colour-white;
                }
            }

            &:hover {
                span {
                    top: $spacing-xs;
                    left: -140px;
                }
            }

            &:focus {
                span {
                    opacity: 0;
                }
            }
        }

        &.previous,
        &.completed {
            background-color: $colour-affirmative;
        }

        &.active {
            background-color: $colour-black;
        }
    }

    @media screen and (min-width: $container-xs) {
        position: absolute;
        top: 40px;
        flex-direction: row;
        right: auto;
        left: 50%;
        transform: translateX(-50%);

        .step-nav-item {
            margin: $spacing-xs;
        }

        &.part-two {
            top: 30px;
        }

        // @media screen and (max-height: $mobile-height-lg) {
        //   top: 110px;
        // }

        // @media screen and (max-height: $mobile-height-md) {
        //   top: 100px;
        // }
    }
}

@keyframes arrowScroll {
    0% {
        opacity: 0;
        top: -10px;
    }

    20%,
    80% {
        opacity: 1;
        top: 0;
    }

    100% {
        opacity: 0;
        top: 10px;
    }
}
