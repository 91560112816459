@import "../../../variables";

.height-weight-step {
  .height-weight-changer {
    padding: $spacing-xl 0;
    position: relative;

    input {
      padding: $spacing-sm;
      width: calc(50% - #{$spacing-sm});
      border: none;
      border-radius: 4px;
      box-sizing: border-box;
      position: relative;
      font-family: $font-bold;
      font-size: 16px;

      &:nth-of-type(1) { margin-right: $spacing-sm; }
      &:nth-of-type(2) { margin-left: $spacing-sm; }

      &.height-cm,
      &.weight-kg {
        width: 100%;
      }
    }

    label {
      position: absolute;
      color: $colour-mid-grey;
      font-family: $font-bold;
      line-height: 22px;
      font-size: 14px;
      z-index: 2;
      padding: $spacing-xs;
      pointer-events: none;

      &:nth-of-type(1) { right: calc(50% + #{$spacing-sm}); }
      &:nth-of-type(2) { right: 0; }

      &.height-cm,
      &.weight-kg {
        right: 0;
      }
    }
  }

  .invalid-measurement {
    color: $colour-error;
  }
}
