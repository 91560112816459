@import "../../variables";

.test-order-placed {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 50px);
  overflow-y: scroll;
  z-index: 9999;
  background: -moz-linear-gradient(top, $colour-white 30%, $colour-secondary-gradient 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, $colour-white 30%, $colour-secondary-gradient 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, $colour-white 30%, $colour-secondary-gradient 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  .order-confirmation {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: $spacing-xxxl;
    box-sizing: border-box;

    img {
      width: 150px;
    }

    h2 {
      font-weight: 400;
      color: $colour-primary;
    }

    p {
      font-family: $font-bold;
    }
  }
}


.return {
  position: fixed;
  bottom: $spacing-md;
  text-align: center;
  font-size: 14px;
  width: 100%;
  // transition: opacity 0.25s ease-out, bottom 0.4s ease-out;
  cursor: pointer;
  text-decoration: none;

  &.leaving {
    opacity: 0;
    bottom: $spacing-xxxl;
  }

  h3 {
    font-size: 18px;
    color: $colour-primary;
  }

  img {
    width: 37px;
    position: relative;
    animation: arrowScroll 2s infinite;
  }

  @media screen and (max-height: $mobile-height-md) {
    bottom: $spacing-sm;
    font-size: 12px;

    img {
      width: 20px;
    }
  }
}

