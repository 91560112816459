@import "../../../variables";

.medical-conditions-step {
  .medical-conditions-items {
    display: flex;
    flex-wrap: wrap;

    .medical-conditions-item {
      // transition: color 0.2s linear, border-color 0.2s linear, top 1s ease-out, background-color 0.25s ease-out, color 0.25s ease-out;
      font-size: 12.5px;
      line-height: 14px;
      width: 123px;
      box-sizing: border-box;
      height: 60px;
      padding: 0 1px;

      &.no-medical-conditions {
        width: 255px;

        @media screen and (max-width: $container-mobile-lg) {
          width: 65px;
        }
      }

      @media screen and (min-width: $container-sm) {
        width: 120px;

        &.no-medical-conditions {
          width: 120px;
        }
      }

      &:hover {
        color: $colour-dark-grey;
      }

      &.selected {
        margin: $spacing-xxs;
        border: solid 1px $colour-light-grey;
        background-color: $colour-light-grey;
        color: $colour-black;
        background-image: url("../../../../public/assets/check.svg");
        background-repeat: no-repeat;
        background-size: auto 75%;
        background-position: center;
      }

      @media screen and (max-height: $mobile-height-sm) {
        width: 65px;
        height: 65px;
        font-size: 10.5px;
      }
    }
  }
}