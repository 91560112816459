@import "../../variables";

.required-test-order-placed {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 50px);
  overflow-y: scroll;
  z-index: 9999;

  .order-confirmation {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    padding: $spacing-xxxl;
    box-sizing: border-box;
    max-width: $container-xxs;
    margin: 0 auto;

    
  @media screen and (max-width: $container-sm) {
    padding-bottom: 200px;
  }

  .address {
    font-family: $font-bold;
  }


    img {
      width: 150px;
    }

    h2 {
      font-weight: 400;
      color: $colour-primary;
      margin: $spacing 0;
      text-align: center;
    }

    p {
      margin: $spacing 0;
      width: 100%;

      strong {
        color: $colour-error;
      }
    }
  }
}
