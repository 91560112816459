@import "../../variables.scss";

input[type=number].linked-range-input {
  width: 100%;
  background: transparent;
  border: none;
  text-align: center;
  font-size: 60px;
  font-family: $font-default;
  outline: none;
  color: $colour-primary;
  position: relative;
  top: 30px;
  // transition: top 1s ease-out;
  box-sizing: border-box;

  @media screen and (max-height: $mobile-height-md) {
    font-size: 42px;
    height: 42px;
  }
}

.nudgers {
  position: absolute;
  right: $spacing;
  top: $spacing;

  > div {
    width: 30px;
    height: 25px;
    background-color: $colour-white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px
  }

  .up {
    margin-bottom: 4px;
    border-top-left-radius: $spacing-xs;
    border-top-right-radius: $spacing-xs;
    background-image: url("../../../public/assets/prev-step.png");
  }

  .down {
    margin-top: 4px;
    border-bottom-left-radius: $spacing-xs;
    border-bottom-right-radius: $spacing-xs;
    background-image: url("../../../public/assets/next-step.png");
  }

  @media screen and (max-height: $mobile-height-md) {
    top: -$spacing-xxs;
    right: $spacing-xxs;
  }
}

.step.active input[type=number].linked-range-input,
.step.previous input[type=number].linked-range-input {
  top: 0;
}
