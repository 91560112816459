@import "../../variables";

.what-happens {
  min-height: calc(100vh - 275px);

  > .content {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    padding: $spacing-xxl $spacing-xl;
    box-sizing: border-box;
    max-width: $container-xxs;
    margin: 0 auto;

    h2 {
      font-weight: 400;
      color: $colour-primary;
      margin: $spacing-xs 0;
      max-width: $container-mobile;
      text-align: center;
    }

    p {
      margin: $spacing-xs 0;
      strong {
        color: $colour-error;
      }
    }

    h4 {
      text-align: left;
      color: $colour-primary;
      width: 100%;
      margin: $spacing 0;
    }

  }

  .button {
    width: 100px;
    font-size: 18px;
    line-height: 20px;
    margin: $spacing-xs auto;
  }
}  
