@import "../../variables.scss";

.range-group {
  position: relative;
  margin-bottom: $spacing-xxl;

  &.small {
   .linked-range-input {
     font-size: 30px;
   }

   .nudgers {
     top: -15px;
   }
  }

  .range-lookup-label {
    font-size: 22px;
    color: $colour-primary;
    text-align: center;
    font-weight: 500;
    margin: $spacing-xs 0;

    @media screen and (max-height: $mobile-height-md) {
        margin: $spacing-xxs 0;
        font-size: 16px;
    }
  }
}
