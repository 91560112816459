@import "../../variables";

.field-card {
  border: none;
  border-radius: 5px;
  max-width: 296px;
  margin: 0 0 $spacing 0;
  background-color: $colour-offwhite;
  padding: 0;
  position: relative;
  z-index: 2;

  &.open-modal {
    z-index: 5;
  }
  
  @media screen and (min-width: $container-sm) {
    max-width: 420px;
  }

  header {
    border-bottom: solid 2px $colour-white;
    padding: $spacing-sm $spacing;
    background: transparent;
    display: flex;
    justify-content: space-between;

    h4 {
      font-size: $base-font-size;
      font-family: $font-bold;
      line-height: 20px;
      margin: 0;
      color: $colour-primary;

      @media screen and (min-height: $container-sm) {
         font-size: 18px;
      }
    }

    p {
      font-size: 14px;
      color: $colour-dark-grey;
    }
  }
  
  .help {
    flex: 0 0 27px;
    height: 26px;
    margin-top: -2px;
    border-radius: 15px;
    background-image: url('../../../public/assets/help.png');
    background-repeat: no-repeat;
    background-position: center;
    text-indent: -9999px;
    color: $colour-white;
    display: block;
    font-family: $font-bold;
    text-align: center;
    font-size: 14px;
    line-height: 26px;
    cursor: pointer;
    
    @media screen and (min-height: $container-sm) {
      font-size: 16px;
    }
  }

  p.with-help {
    display: flex;
    align-items: center;
  }

  article {
    padding: $spacing-xl $spacing $spacing $spacing;
    // transition: padding 1s ease-out;
  }

  @media screen and (max-height: $mobile-height-md) {
    header {
      h4 {
        font-size: 14px;
        line-height: 20px;;
      }
    }

    article {
      padding: $spacing-sm $spacing;
    }
  }

  @media screen and (max-height: $mobile-height-sm) and (max-width: $container-sm) {
    max-width: 256px;
    margin-left: $spacing-md;

    header {
      padding: $spacing-xs $spacing-sm;

      h4 {
        font-size: 14px;
        line-height: 18px;
      }

      p {
        margin: $spacing-xs 0;
        font-size: 11px;
        line-height: 14px;
      }
    }

    article {
      padding: $spacing-xs $spacing-sm;
    }
  }
}

.step.active,
.step.previous {
  .field-card {
    article {
      padding-top: $spacing;

      @media screen and (max-height: $mobile-height-sm) {
        padding-top: $spacing-xs;
      }
    }
  }
}

.unit-switcher {
  font-size: 12px;
  line-height: 22px;
  position: relative;

  &:before {
    content: ' ';
    display: block;
    background-color: $colour-white;
    width: 45px;
    height: 22px;
    border-radius: 11px;
    float: right;
    margin-left: $spacing-sm;
    box-shadow: inset -1px 1px 4px 0 rgba(97, 97, 97, 0.5);
    cursor: pointer;
  }

  &:after {
    content: ' ';
    display: block;
    height: 16px;
    width: 16px;
    box-shadow: 1px 1px 1px 0 rgba(138, 138, 138, 0.5);
    background-color: $colour-primary;
    border-radius: 11px;
    position: absolute;
    right: 25px;
    top: 3px;
    // transition: right 0.2s ease-out;
  }

  &.on {
    &:after {
      right: 5px;
    }
  }
}
