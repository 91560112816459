@import "variables";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");

html,
body {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
    position: fixed;
}

body {
    margin: 0;
    padding: 0;
    font-family: $font-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $colour-grey;

    main {
        margin: 0 auto;
        width: 100%;
        min-height: calc(100vh - 305px);
        background-color: $colour-white;

        &.ios {
            min-height: calc(100vh - 160px);

            .home-container,
            .terms-container,
            .cookies-container,
            .help-container,
            .about-container,
            .disclaimers-container {
                padding-bottom: 120px;
            }

            .test-container {
                height: calc(100vh - 160px);
            }
        }
    }

    &.show-cookie-overlay {
        .cookies-overlay {
            display: block;
        }

        #tidio-chat {
            opacity: 0;
            pointer-events: none;
        }
    }

    &.show-chat {
        #tidio-chat {
            opacity: 1;
            pointer-events: all;
        }
    }

    &.hide-chat {
        #tidio-chat {
            opacity: 0;
            pointer-events: none;
        }
    }
}

#root {
    position: relative;
    padding-top: 86px;
    height: calc(100vh - 86px);
    overflow-y: scroll;
    scroll-behavior: smooth;
    background-color: $colour-white;

    @media screen and (max-width: $container-sm) {
        padding-top: 69px;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

input {
    &:focus {
        border: solid 1px $colour-dark-grey;
    }

    &[type="number"] {
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }
}

.clearfix {
    clear: both;
}

@media screen and (max-width: $container-sm) {
    .desktop-only {
        display: none !important;
    }
}

@media screen and (min-width: ($container-sm + 1)) {
    .small-devices-only {
        display: none !important;
    }
}
