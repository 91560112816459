@import "../../variables";

.summary {
  margin: 0 auto;
  transition: opacity 0.5s ease-out;//, top 0.75s ease-out;
  position: relative;
  top: 80px;
  opacity: 0;

  .summary-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: $spacing-lg auto;
    max-width: $container-xs;

    @media screen and (max-width: $container-xs) {
      margin: $spacing-lg;
    }
    
    h2 {
      font-weight: normal;
      text-align: right;
      font-size: 24px;
      margin: 0;
    }
  }

  .summary-intro {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h3 { 
      margin: $spacing-xs 0;
      text-align: center;
      font-size: 22px;
      max-width: 50vw;
    }

    p {
      margin: $spacing-xs $spacing-lg $spacing-lg $spacing-lg;
      font-size: 14px;
      text-align: center;
    }
  }

  .heart-health {
    margin: $spacing-xxs auto;
    border: solid 1px $colour-primary;
    border-radius: 5px;
    max-width: $container-xs;
    width: calc(100% - #{$spacing-lg} - #{$spacing-lg});

    h3 {
      margin: 0;
      padding: $spacing-sm 0;
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      color: $colour-white;
      font-weight: bold;
      font-size: 18px;
      background-color: $colour-primary;
      max-width: none;
    }

    section {
      margin: $spacing-sm;
      // border: solid 1px $colour-primary;
      border-radius: 5px;
      background-color: $colour-white;

      h4 {
        margin: 0;
        padding: $spacing-sm 0;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        color: $colour-primary;
        font-weight: bold;
        font-size: 16px;
        border-bottom: solid 1px $colour-primary
      }

      .heart-age {
        display: flex;
        justify-content: center;

        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: $spacing-xs $spacing $spacing $spacing;

          p {
            font-weight: 700;
            font-size: 14px;
            margin: $spacing-xs 0;
          }
          
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: $colour-white;
            font-size: 18px;
            font-weight: 700;
            width: 50px;
            height: 50px;
            text-align: center;
            box-shadow: 0px 0px 10px $colour-mid-grey;
          }

          &:last-of-type {
            span {
              height: 70px;
              width: 70px;
              font-size: 32px;
            }
          }
          
        }
      }

      .relative-risk {
        position: relative;
        img {
          margin: 0 auto;
          display: block;
        }

        p {
          font-weight: 700;
          text-align: center;
          margin: $spacing $spacing-xs $spacing $spacing-xs;
          position: relative;
            
          &:first-of-type {
            margin-bottom: -$spacing-sm;
          }
        }

        span {
          position: absolute;
          display: flex;
          height: 30px;
          width: 30px;
          padding: $spacing-sm;
          border-radius: 50%;
          color: $colour-white;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          box-shadow: 0px 0px 10px rgba(0,0,0,0.7);
          border: solid 2px $colour-white;
          left: 50%;
          top: 80px;
          transform: translateX(-50%);

          &.high-risk { background-color: $colour-risk-high; }
          &.low-risk { background-color: $colour-risk-low; }
        }
      }

      .qrisk {
        .risk-range {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin: $spacing-xs $spacing $spacing $spacing;

          p {
            font-weight: 700;
            margin: $spacing-xs 0;
            width: calc(100% - 90px);
          }
          
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: $colour-white;
            font-size: 18px;
            font-weight: 700;
            width: 70px;
            height: 70px;
            text-align: center;
            box-shadow: 0px 0px 10px $colour-mid-grey;
          }
        }

        .people {
          width: 200px;
          margin: 0 auto $spacing-lg auto;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;

          img {
            width: 16px;
            margin: 2px;
          }
        }

        > p {
          margin: $spacing-lg;
          text-align: center;
          font-size: 16px;
        }
      }
    }
  }

  .send-to-gp {
    .button {
      width: 180px;
      font-size: 16px;
      line-height: 20px;
    }

    p {
      text-align: center;
      margin: $spacing-lg;
    }
  }

  .back-to-test {
    display: inline-block;
    font-size: 14px;
    text-decoration: none;
    border-radius: 25px;
    background-color: $colour-offwhite;
    color: $colour-dark-grey;
    padding: $spacing-sm $spacing $spacing-sm $spacing-xl;
    background-image: url(../../../public/assets/back-black.png);
    background-repeat: no-repeat;
    background-position: 12px center;
    background-size: 7px;
  }

  .dials {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: $container-xs;
    margin: 0 auto;

    @media screen and (min-width: $container-xs) {
      justify-content: space-between;
    }
  }

  &.mounted {
    top: 0;
    opacity: 1;
  }

  h3 {
    font-weight: normal;
    text-align: left;
    font-size: 18px;
    margin: $spacing-xxl auto;
    color: $colour-primary;
    width: 87.5%;
    max-width: 680px;
  }

  .risk-percentage {
    padding: $spacing-xl;
    border-radius: 44px;
    border: solid 6px $colour-primary;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    width: 87.5%;
    max-width: 680px;
    margin: 0 auto;

    p {
      margin: 0;
      color: $colour-primary;
      font-family: $font-bold;
      font-size: 20px;
      line-height: 20px;

      &.risk-value {
        font-family: $font-bold;
        color: $colour-primary-darker;
        font-size: 28px;
        line-height: 20px;
      }
    }

    span {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background-color: $colour-primary;
      opacity: 0.15;
    }
  }
  
  .improvements {
    width: 80%;
    max-width: 640px;
    margin: 0 auto;

    a {
      background-color: $colour-light-grey;
      display: block;
      margin: $spacing auto;
      padding: $spacing-sm $spacing;
      border-radius: $spacing-lg;
      text-decoration: none;
      font-family: $font-bold;
      color: $colour-dark-grey;
      background-image: url("../../../public/assets/prev-step.png");
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: 95% center;
    }
  }

  .high-risk-free-test {
    display: flex;
    width: 80%;
    max-width: $container-mobile-lg;
    margin: $spacing auto;
    padding: $spacing-lg;
    flex-direction: row;
    box-sizing: border-box;
    flex-wrap: wrap;
    align-items: center;

    img {
      align-self: center;
      display: block;
      width: auto;
      margin: $spacing-sm $spacing-lg $spacing-sm 0;
      flex: 20%;
    }
    
    @media screen and (max-width: $container-xs) {
      flex-direction: column;
    }

    p {
      flex: 70%;
      font-size: 14px;

      strong {
        color: $colour-primary;
      }
    }

    .button {
      width: auto;
      font-size: 16px;
      margin: 0 auto;
    }
  }

  .heart-health, .dials {
    .button {
      width: 100px;
      font-size: 14px;
      line-height: normal;
      padding: $spacing-xs;
      background-color: $colour-primary;
      border: none;
      background-position: right 10px center;
      background-image: url("../../../public/assets/details.png");
      background-repeat: no-repeat;
      background-size: 20px;
      text-align: left;

      &:after {
        display: none;
      }
    }
  }
}

.risk-results {
  width: 90%;
  max-width: $container-xs;
  margin: $spacing-lg auto;
  
  h2 {
    color: $colour-white;
    font-family: $font-bold;
    margin: $spacing-xl auto 0 auto;
    padding: $spacing-sm 0;
    font-size: 18px;
    text-align: center;
    background-color: $colour-primary;
  }

  h3 {
    color: $colour-white;
    font-family: $font-bold;
    margin: 0 auto;
    padding: $spacing-xs 0;
    font-size: 16px;
    text-align: center;
    background-color: $colour-primary-lighter;
    width: auto;
    max-width: none;
  }

  h4 {
    color: $colour-primary;
  }

  .risk-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      > div {
        flex: 100%;
      }

      .section-summary {
        font-size: 18px;
        font-family: $font-bold;
        text-align: center;
        display: block;
        max-width: $container-mobile;
        margin: 0 auto;
      }

      .dial-container {
        flex: 100px;
    
        @media screen and (max-width: $container-xs) {
          flex: 80px;
        }
    
        .dial {
          margin-bottom: -40px;
        }   
      }

      p, ul {
        margin: $spacing 0;
        font-size: 16px;
        line-height: 22px;
      }

      a { 
        display: block;
        color: $colour-error;
        padding: $spacing-sm $spacing-lg;
        border: solid 1px $colour-mid-grey;
        text-align: center;
        max-width: $container-mobile;
        margin: $spacing auto $spacing-xl auto;
        border-radius: 5px;
        position: relative;

        &:before {
          content: ' ';
          display: block; 
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 10px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width:  7.5px 0 7.5px 10px;
          border-color:  transparent transparent transparent $colour-error;
        }
      }
  }

  .scroll-to-top {
    padding: $spacing-lg;
    width: 200px;
    margin: 0 auto;
    text-align: center;
    font-size: 13px;
    display: flex;
    justify-content: center;
    cursor: pointer;

    img {
      width: 16px;
      align-self: center;
      margin: 0 $spacing-xs;
    }
  }
}

.cholesterol-frame {
  position: fixed;
  width: 100vw;
  height: 0;
  top: 100vh;
  left: 0;
  background-color: $colour-white;
  z-index: 200;
  opacity: 0;
  pointer-events: none;
  // transition: top 0.5s ease-out;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  &.visible {
    opacity: 1;
    height: 100vh;
    top: 0px;
  }
}