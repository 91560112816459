@import "../../variables";

.button {
    border-radius: 6px;
    padding: $spacing-xs;
    line-height: 28px;
    font-size: 20px;
    font-family: $font-bold;
    margin: $spacing-sm auto;
    display: block;
    text-align: center;
    text-decoration: none;
    background-color: $colour-secondary;
    color: $colour-white;
    position: relative;
    cursor: pointer;
    border-bottom: solid 3px $colour-black;
    width: 100px;

    &:after {
        display: block;
        content: " ";
        height: 6px;
        position: absolute;
        bottom: -6px;
        left: 0;
        background-color: $colour-black;
        width: 100%;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    &.primary {
        margin: $spacing-lg auto;
        width: 200px;
        padding: $spacing $spacing-xl;
        background-color: $colour-secondary-lighter;
        font-size: 23px;
    }

    &.disabled {
        pointer-events: none;
        cursor: default;
        opacity: 0.4;
    }

    &:active,
    &:focus {
        top: 3px;

        &:after {
            height: 3px;
            bottom: -3px;
        }
    }

    &.greyed {
        background-color: $colour-grey;
        color: $colour-mid-grey;
        border: none;

        &:after {
            display: none;
        }

        &:active,
        &:focus {
            top: auto;
        }
    }

    @media screen and (max-height: $mobile-height-md) {
        font-size: 16px;
        line-height: 22px;
        padding: $spacing-sm $spacing;
    }
}
