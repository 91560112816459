@import "../../variables.scss";

.terms-container {
    max-width: $container-sm;
    margin: 0 auto;
    padding: $spacing-lg;

    h1 {
        color: $colour-primary;
        font-weight: 400;
    }
}