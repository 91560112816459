@import "../../../variables";

section.blood-pressure-step {
    .bp-warning {
        border-radius: 10px;
        padding: $spacing;
        background: $colour-error;
        color: $colour-white;
        margin: 0 auto;
        box-sizing: border-box;
        display: flex;

        .warning-icon {
            background-image: url("../../../../public/assets/alert_black.png");
            height: 20px;
            width: 20px;
            flex: 20px;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: $spacing-sm;
        }

        > div {
            width: calc(100% - 20px);
        }

        p {
            display: inline;
            margin: 0;
            margin-right: $spacing-xs;
        }
    }

    .invalid-readings {
        margin: $spacing 0 0 0;
        color: $colour-error;
        font-size: 13px;
        font-family: $font-bold;
    }

    .blood-pressure-advice {
        padding: $spacing-xs $spacing-lg;

        p {
            font-size: 14px;
            margin: 0;
        }
    }

    .blood-pressure-reading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 0 $spacing-sm;

        &.key {
            height: auto;
        }

        p {
            font-size: 13px;
            line-height: 18px;
            margin: 0 $spacing-sm;
            color: $colour-dark-grey;
            flex: 1;
            display: flex;
            align-content: center;

            @media screen and (max-width: $container-mobile) {
                margin: 0 $spacing-xxs;
                font-size: 12px;
            }
        }

        &.key {
            align-items: flex-end;
            justify-content: flex-end;

            p {
                line-height: 14px;
                width: 80px;
                text-align: center;
                margin: $spacing-xs 0;
                display: block;
                flex: initial;
            }
        }
    }

    .pulse-changer {
        width: 120px;
        margin: 0 auto;
    }

    .pulse-changer,
    .blood-pressure-changer {
        padding: 0;
        position: relative;

        input {
            padding: $spacing-sm;
            border: none;
            width: 75px;
            box-sizing: border-box;
            position: relative;
            font-family: $font-bold;
            font-size: 16px;
            font-family: $font-default;
            text-align: center;

            &:first-of-type {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
            &:last-of-type {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }

        .separator {
            background-color: $colour-white;
            line-height: 38px;
            position: relative;
            top: 3px;
            display: inline-flex;
            align-content: center;
            font-size: 24px;
            color: $colour-light-grey;
            font-weight: 400;

            &.remote {
                background-color: transparent;
            }
        }

        label {
            position: absolute;
            color: $colour-mid-grey;
            line-height: 22px;
            font-size: 14px;
            z-index: 2;
            padding: $spacing-xs;
            pointer-events: none;
            right: $spacing-xxs;
        }

        &.current-reading {
            width: 50%;
            margin: 0 auto;

            input {
                width: 100%;
            }

            label {
                right: 0;
            }
        }
    }

    .blood-pressure-locations {
        padding: $spacing-sm;

        .blood-pressure-location {
            padding: $spacing-sm 0;

            label {
                padding-left: $spacing-xl;
                display: block;
                position: relative;
                font-size: 14px;

                &:before {
                    content: " ";
                    display: block;
                    height: 24px;
                    width: 24px;
                    border-radius: $spacing-lg;
                    background-color: $colour-white;
                    position: absolute;
                    left: 0;
                }
            }

            p,
            div {
                display: none;
            }

            &.selected {
                label {
                    &:after {
                        content: " ";
                        display: block;
                        height: 12px;
                        width: 12px;
                        border-radius: $spacing;
                        background-color: $colour-black;
                        position: absolute;
                        left: 6px;
                        top: 6px;
                    }
                }

                .input-field {
                    margin-bottom: 0;
                }

                p,
                div {
                    display: block;
                    margin: $spacing-xs 0 0 $spacing-xl;
                    color: $colour-primary;
                    font-size: 14px;
                    font-family: $font-bold;

                    label {
                        padding: 0;

                        &:before,
                        &:after {
                            display: none;
                        }
                    }

                    div {
                        margin-left: 0;
                    }
                }

                input {
                    width: 100%;
                }
            }
        }
    }

    .info-modal {
        .actions {
            display: flex;
            justify-content: center;

            .button {
                margin: $spacing;
            }
        }
    }

    iframe {
        display: block;
        margin: $spacing auto;
        width: 320px;
        height: 180px;

        @media screen and (max-width: $container-sm) {
            width: 240px;
            height: 130px;
        }
    }
}

.highlight-error {
    section.blood-pressure-step {
        .invalid-readings {
            padding: $spacing;
            border: solid 2px $colour-error;
        }
    }
}
