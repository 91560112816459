@import "../../../variables.scss";

.diabetes-risk-step {
    p {
        font-size: 14px;
    }

    .diabetes-family-items {
        display: flex;
        justify-content: center;

        .diabetes-family-item {
            padding: 5px;
            width: 65px;
            height: 45px;

            @media screen and (max-height: $container-xs) {
                height: 25px;
            }
        }
    }

    img {
        display: block;
        margin: 0 auto;
    }

    .waist-measurement-items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 320px;
        margin: 0 auto;

        > p {
            width: 100%;
            margin: 0;
            line-height: 5px;
        }

        .waist-measurement-item {
            width: 200px;
            height: 50px;
        }
    }

    @media screen and (max-height: $mobile-height-lg) {
        p {
            font-size: 12.5px;
        }

        .diabetes-family-items {
            .diabetes-family-item {
                margin: $spacing-xxs $spacing-sm;
                width: 65px;
                height: 65px;
            }
        }

        .waist-measurement-items {
            flex-direction: row;
            flex-wrap: wrap;

            .unit-switcher {
                width: 165px;
            }

            .waist-measurement-item {
                width: 120px;
            }
        }
    }

    @media screen and (max-height: $mobile-height-md) {
        .waist-measurement-items {
            .waist-measurement-item {
                width: 100px;
            }
        }
    }

    .waist-measurement-changer {
        padding: $spacing-xl 0;
        position: relative;

        div {
            width: 200px;
            margin: 0 auto;
            position: relative;

            input {
                padding: $spacing-sm;
                width: 100%;
                border: none;
                border-radius: 4px;
                box-sizing: border-box;
                position: relative;
                font-family: $font-bold;
                font-size: 16px;
            }

            label {
                position: absolute;
                color: $colour-mid-grey;
                font-family: $font-bold;
                line-height: 22px;
                font-size: 14px;
                z-index: 2;
                padding: $spacing-xs;
                pointer-events: none;
                right: 0;
            }
        }

        .invalid-measurement {
            color: $colour-error;
        }
    }
}
