@import "../../../variables.scss";

section.alcohol-step {
    .alcohol-dependency-items {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: $spacing-xl;

        .alcohol-dependency-item {
            width: 120px;
            height: 55px;
            font-size: 14px;
            line-height: 1.14;
            padding: $spacing-sm $spacing;
            box-sizing: border-box;
            font-size: 13px;

            &.selected {
                width: 122px;
                margin-top: $spacing-xxs;
                padding: $spacing 14px;
                margin-left: $spacing-xxs;
            }
        }
    }
    
    .warning {
        margin-top: 0;
        color: $colour-risk-high;
        font-weight: 300;
    }

    p {
        margin-top: $spacing-xl;
        margin-bottom: $spacing-xs;
    }

    @media screen and (max-height: $mobile-height-md) {
        p {
            font-size: 12.5px;
        }
    }
}