@import "../../variables.scss";

.contact-container {
    max-width: $container-sm;
    margin: 0 auto;
    padding: $spacing-lg;

    h1 {
        color: $colour-primary;
        font-weight: 400;
    }

    form {
        max-width: $container-mobile-lg;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        label {
            font-family: $font-bold;
            font-size: 14px;
        }

        input, textarea {
            margin: $spacing-sm 0;
            border: solid 1px $colour-mid-grey;
            border-radius: 5px;
            padding: $spacing-sm;
        }

        .error {
            font-family: $font-bold;
            color: $colour-primary;
            font-size: 13px;
        }

        textarea {
            min-height: 100px;
            resize: vertical;
        }
    }
}