@import "../../variables";

header {
    padding: 0 17px;
    background-color: $colour-primary;

    &.main-nav-header {
        position: fixed;
        top: 0;
        width: 100%;
        box-sizing: border-box;
        z-index: 2000;

        @media screen and (min-width: $container-xs) {
            width: calc(100% - 17px);
            padding-right: 0;
        }

        &.home-header {
            // background-color: transparent;
        }
    }

    nav {
        margin: 0 auto;
        max-width: $container-xs;
        display: flex;
        justify-content: space-between;

        @media screen and (min-width: $container-sm) {
            justify-content: center;
        }

        .logo {
            transition: all 0.25s linear;
            position: relative;

            &.hidden {
                opacity: 0;
                left: -$spacing;
                pointer-events: none;
            }

            &.visible {
                left: 0;
                opacity: 1;
                pointer-events: all;
            }

            img {
                display: block;

                &.desktop-only {
                    height: 66px;
                    image-rendering: -webkit-optimize-contrast;
                }
            }

            @media screen and (max-width: $container-sm) {
                max-width: 80%;
                height: 49px;
                display: flex;
                align-items: center;

                img {
                    width: 100%;
                }
            }
        }

        a {
            color: $colour-white;
            margin: $spacing-sm 0;
            font-family: $font-bold;
            text-decoration: none;
            line-height: 28px;
        }

        .hamburger {
            display: flex;
            cursor: pointer;
            width: 20px;
            flex-direction: column;
            padding: $spacing-xs;
            margin: $spacing 0;
            align-items: center;
            justify-content: center;
            background-color: $colour-primary-darker;

            @media screen and (min-width: $container-sm) {
                display: none;
            }

            span {
                border-top: solid 3px $colour-white;
                margin: 2px 0;
                width: 100%;
            }
        }
    }
}

.session-timeout {
    color: $colour-white;
    background-color: $colour-error;
    padding: $spacing;
    text-align: center;
    font-family: $font-bold;
}

.flyout-navigation {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100vw;
    height: 100vh;
    background-color: $colour-almostwhite;
    z-index: 3000;
    transition: left 0.4s ease-out;

    &.open {
        left: 0;
    }

    section {
        display: flex;
        flex-direction: column;
        padding: 0 $spacing-xxl;
        max-width: $container-mobile;
        margin: 0 auto;
        height: 100%;
        justify-content: center;

        .close {
            position: absolute;
            top: $spacing-xxl;
            left: $spacing-xxl;
            font-size: 32px;
            cursor: pointer;
            font-weight: lighter;
        }

        a {
            text-decoration: none;
            color: $colour-primary;
            font-size: 18px;
            font-family: $font-bold;
            margin: $spacing-sm 0;
        }

        .sub-links {
            margin-top: $spacing-xxxl;
            padding-top: $spacing-xxxl;
            display: flex;
            flex-direction: column;

            a {
                font-size: 16px;
                font-weight: 400;
                color: $colour-black;
                margin: $spacing-xs 0;
            }
        }

        img {
            align-self: flex-start;
            width: 100px;
            margin-bottom: $spacing;
            margin-left: -$spacing;
        }
    }
}
