@import "../../variables.scss";

$dialSize: 140px;

.static-dial-container {
    margin: $spacing-xxs;
    border: solid 1px $colour-primary;
    width: $dialSize + $spacing + $spacing;
    border-radius: 5px;
    background-color: $colour-white;

    @media screen and (min-width: $container-xs) {
        width: calc(50% - #{$spacing});
        margin: $spacing-xs 0;
    }

    &.warning {
        > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
                margin: $spacing-sm $spacing 0 $spacing;
            }

            p {
                color: $colour-risk-high;
                margin: $spacing-sm;
                font-size: 14px;
                font-family: $font-bold;
                text-align: center;
            }

            @media screen and (min-width: $container-xs) {
                p {
                    margin: $spacing $spacing-xxl;
                    font-size: 16px;
                }

                img {
                    margin: $spacing;
                }
            }
        }
    }

    &.boxless {
        margin-top: $spacing;
        border: none;
        background-color: transparent;

        h3 {
            display: none;
        }

        .dial {
            margin: $spacing-sm 0;
            padding: 0;

            img {
                display: none;
            }

            .counter {
                margin: 0 auto;
                width: 60px;
                height: 60px;
                position: relative;
                top: auto;
                left: auto;
                box-shadow: none;
                font-size: 21px;
            }

            &.no-activity,
            &.high-activity,
            &.raised,
            &.raised_med,
            &.healthy {
                .counter {
                    font-size: 18px;
                }
            }

            &.increased,
            &.low-activity,
            &.med-activity {
                .counter {
                    font-size: 14px;
                }
            }
        }
    }

    &.disabled {
        .dial {
            opacity: 0.25;
            pointer-events: none;

            .counter,
            .indicator {
                text-indent: -9999px;
                background-color: $colour-white !important;
            }
        }
    }

    &.no-boundaries {
        .dial {
            .counter {
                border: solid 2px $colour-primary;
                background-color: $colour-white !important;
                color: $colour-black;
            }
        }
    }

    h3 {
        margin: 0;
        padding: $spacing-sm 0;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        color: $colour-white;
        font-family: $font-bold;
        font-size: 18px;
        background-color: $colour-primary;
    }

    .dial {
        position: relative;
        padding: $spacing 0;

        img {
            display: block;
            position: relative;
            width: 170px;
            margin: 0 auto;
            padding-bottom: $spacing-xl;
        }

        .counter {
            background-color: $colour-risk-low;
            color: $colour-white;
            position: absolute;
            top: calc(50% - #{$spacing-sm} - 30px);
            left: calc(50% - #{$spacing-sm} - 15px);
            height: 30px;
            width: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: $spacing-sm;
            border: solid 2px $colour-white;
            font-family: $font-bold;
            font-size: 18px;
            border-radius: 50%;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
            z-index: 25;
            transition: background-color 1s;
            transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
            text-align: center;

            span {
                display: block;
                font-size: 14px;
                line-height: 11px;
                margin-top: $spacing-xxs;
            }
        }

        &.low-risk,
        &.healthy,
        &.high-activity {
            .counter {
                background-color: $colour-risk-low;
            }
        }

        &.healthy {
            .counter {
                font-size: 12px;
            }
        }

        &.raised,
        &.raised_med {
            .counter {
                font-size: 14px;
            }
        }

        &.increased {
            .counter {
                font-size: 11px;
            }
        }

        &.increased,
        &.raised_med,
        &.med-risk,
        &.med-activity {
            .counter {
                background-color: $colour-risk-med;
            }
        }

        &.high-risk,
        &.raised,
        &.no-activity {
            .counter {
                background-color: $colour-risk-high;
            }
        }

        &.low-activity {
            .counter {
                background-color: $colour-risk-medhigh;
            }
        }

        &.custom-interpretation {
        }

        &.with-lower-bound {
            &.too-low {
                .counter {
                    background-color: $colour-risk-high;
                }
            }
        }

        &.with-higher-bound {
            &.high-risk {
                .counter {
                    background-color: $colour-risk-medhigh;
                }
            }

            &.very-high-risk {
                .counter {
                    background-color: $colour-risk-high;
                }
            }
        }
    }
}
